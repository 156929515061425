/**
 * Class for instrument api calls
 */
import { backendLookup, handleResponse } from "../lookup";

export class InstrumentAPIRouter {
  postInstrument = async (changeMessage, instrument, instrumentValues) => {
    try {
      const response = await backendLookup(
        "POST",
        `${instrument}/`,
        instrumentValues
      );
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  getAllInstruments = async (changeMessage, instrument, page) => {
    try {
      const response = await backendLookup(
        "GET",
        `${instrument}/?page=${page || 1}`
      );
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
    }
  };

  updateInstrument = async (changeMessage, instrument, instrumentId, newValues) => {
    try {
      const response = await backendLookup(
        "PUT",
        `${instrument}/${instrumentId}/`,
        newValues
      );
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  deleteInstrument = async (changeMessage, instrument, instrumentId) => {
    try {
      const response = await backendLookup(
        "DELETE",
        `${instrument}/${instrumentId}/`
      );
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  updateStand = async (changeMessage, newValues) => {
    try {
      const response = await backendLookup(
        "POST",
        `stand_instruments/update_stand/`,
        newValues
      );
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  getEditStands = async (changeMessage, newValues) => {
    try {
      const response = await backendLookup(
        "GET",
        `stands/get_edit_stands/`,
        newValues
      );
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };
}
