/**
 * Page listing all instruments and stands.
 * Allows CRUD functions for instruments and stands.
 */

import * as React from "react";
import { styled } from "@mui/material/styles";
import Box from "@mui/material/Box";
import ButtonBase from "@mui/material/ButtonBase";
import Typography from "@mui/material/Typography";
import meterPng from "./meter.png";
import generatorPng from "./generator.png";
import standPng from "./stand.png";
import powerHeadPng from "./power_head.png";
import Controls from "../controls/Controls";
import {
  PowerGeneratorForm,
  PowerMeterForm,
  StandForm,
  standHeadCells,
  generHeadCells,
  meterHeadCells,
  phHeadCells,
  PowerHeadForm,
  StandEditForm,
} from "./InstrumConstants";
import { InstrumentAPIRouter } from "../Routers/InstrumentAPIRouter";
import { nestedCopy } from "../controls/Constants";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import IconButton from "@mui/material/IconButton";
import Table from "../Table/Table";
import { Grid } from "@mui/material";

const images = [
  {
    url: standPng,
    title: "Stand",
    key: "stands",
    width: "40%",
  },
  {
    url: generatorPng,
    title: "Generator",
    key: "geners",
    width: "30%",
  },
  {
    url: meterPng,
    title: "Meter",
    key: "meters",
    width: "30%",
  },
  {
    url: powerHeadPng,
    title: "Power Head",
    key: "power_heads",
    width: "30%",
  },
];

const ImageButton = styled(ButtonBase)(({ theme }) => ({
  position: "relative",
  height: 200,
  [theme.breakpoints.down("sm")]: {
    width: "100% !important", // Overrides inline-style
    height: 0,
  },
  "&:hover, &.Mui-focusVisible": {
    zIndex: 1,
    "& .MuiImageBackdrop-root": {
      opacity: 0.15,
    },
    "& .MuiImageMarked-root": {
      opacity: 0,
    },
    "& .MuiTypography-root": {
      border: "4px solid currentColor",
    },
  },
}));

const ImageSrc = styled("span")({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: "cover",
  backgroundPosition: "center 40%",
});

const Image = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  color: theme.palette.common.white,
}));

const ImageBackdrop = styled("span")(({ theme }) => ({
  position: "absolute",
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.4,
  transition: theme.transitions.create("opacity"),
}));

const ImageMarked = styled("span")(({ theme }) => ({
  height: 3,
  width: 18,
  backgroundColor: theme.palette.common.white,
  position: "absolute",
  bottom: -2,
  left: "calc(50% - 9px)",
  transition: theme.transitions.create("opacity"),
}));

export default function Instruments(props) {
  const { changeMessage, clearMessage } = props;
  const [instrument, setInstrument] = React.useState("");
  const [tableItems, setTableItems] = React.useState([]);
  const [headCells, setHeadCells] = React.useState([]);
  const [tableCount, setTableCount] = React.useState(0);
  const [instrumentForm, setInstrumentForm] = React.useState({});
  const [formLayout, setFormLayout] = React.useState(null);
  const [editFormLayout, setEditFormLayout] = React.useState(null);

  const getTableItems = async (instrum) => {
    const instrumentAPIRouter = new InstrumentAPIRouter();
    await instrumentAPIRouter.getAllInstruments(changeMessage, instrum).then((response) => {
      setTableItems(response);
      setTableCount(response.length);
    });
  };

  const onInstrumentClick = (key) => {
    setInstrument(key);
    getTableItems(key);
    switch (key) {
      case "stands":
        setHeadCells(standHeadCells);
        setInstrumentForm(StandForm);
        setFormLayout(StandForm.formLayout);
        setEditFormLayout(StandEditForm.formLayout);
        break;
      case "geners":
        setHeadCells(generHeadCells);
        setInstrumentForm(PowerGeneratorForm);
        setFormLayout(PowerGeneratorForm.formLayout);
        setEditFormLayout(null);
        break;
      case "meters":
        setHeadCells(meterHeadCells);
        setInstrumentForm(PowerMeterForm);
        setFormLayout(PowerMeterForm.formLayout);
        setEditFormLayout(null);
        break;
      case "power_heads":
        setHeadCells(phHeadCells);
        setInstrumentForm(PowerHeadForm);
        setFormLayout(PowerHeadForm.formLayout);
        setEditFormLayout(null);
        break;
      default:
    }
  };
  const handleBackClick = () => {
    setInstrument("");
  };

  const handleAdd = () => {
    console.log("Adding an instrument to stand.");
  };

  const handleEdit = async (values) => {
    let instrumentAPIRouter = new InstrumentAPIRouter();
    switch (instrument) {
      case "stands":
        let updated_stand = {
          stand: values.stand,
          gener: values.gener.serial,
          meter: values.meter.serial,
        };
        await instrumentAPIRouter
          .updateStand(changeMessage, updated_stand)
          .then(await getTableItems(instrument));
        break;
      case "geners":
        let generator = {
          power_range: {
            low_power: values.lowPower,
            high_power: values.highPower,
          },
          frequency_range: {
            low_frequency: values.lowFrequency,
            high_frequency: values.highFrequency,
          },
          ip_address: values.ipaddress,
          port: values.port,
          ...values,
        };
        await instrumentAPIRouter
          .updateInstrument(changeMessage, instrument, values.serial, generator)
          .then(await getTableItems(instrument));
        break;
      case "meters":
        let meter = {
          ip_address: values.ipaddress,
          port: values.port,
          ...values,
        };
        await instrumentAPIRouter
          .updateInstrument(changeMessage, instrument, values.serial, meter)
          .then(await getTableItems(instrument));
        break;
      case "power_heads":
        let powerHead = {
          power_range: {
            low_power: values.lowPower,
            high_power: values.highPower,
          },
          frequency_range: {
            low_frequency: values.lowFrequency,
            high_frequency: values.highFrequency,
          },
          cal_factor: {},
          ...values,
        };
        await instrumentAPIRouter
          .updateInstrument(changeMessage, instrument, values.id, powerHead)
          .then(await getTableItems(instrument));
        break;
      default:
    }
  };

  const handleDelete = () => { };

  const handleSubmit = async (values) => {
    const instrumentAPIRouter = new InstrumentAPIRouter();
    switch (instrument) {
      case "stands":
        let stand = { ip_address: values.ipaddress, ...values };
        await instrumentAPIRouter
          .postInstrument(changeMessage, instrument, stand)
          .then(await getTableItems(instrument));
        break;
      case "geners":
        let generator = {
          power_range: {
            low_power: values.lowPower,
            high_power: values.highPower,
          },
          frequency_range: {
            low_frequency: values.lowFrequency,
            high_frequency: values.highFrequency,
          },

          ...values,
        };
        await instrumentAPIRouter
          .postInstrument(changeMessage, instrument, generator)
          .then(await getTableItems(instrument));
        break;
      case "meters":
        let meter = { ...values };
        await instrumentAPIRouter
          .postInstrument(changeMessage, instrument, meter)
          .then(await getTableItems(instrument));
        break;
      case "power_heads":
        let powerHead = {
          power_range: {
            low_power: values.lowPower,
            high_power: values.highPower,
          },
          frequency_range: {
            low_frequency: values.lowFrequency,
            high_frequency: values.highFrequency,
          },
          cal_factor: {},
          ...values,
        };
        await instrumentAPIRouter
          .postInstrument(changeMessage, instrument, powerHead)
          .then(await getTableItems(instrument));
        break;
      default:
    }
  };

  const processInstrument = (key) => {
    switch (key) {
      case "stands":
        return "stand";
      case "geners":
        return "generator";
      case "meters":
        return "meters";
      case "power_heads":
        return "power head";
      default:
    }
  };

  const changeFormLayout = (values) => {
    let formLayoutCopy = nestedCopy(instrumentForm.formLayout);
    for (const value in values) {
      if (values[value] instanceof Object) {
        for (const form of formLayoutCopy) {
          if (form.menuItemName === value && !form.disabled) {
            if (!value) {
              form.defaultItem = values[value];
              break;
            }
            if (form.menuItems.length) {
              let menuItemExists = false;
              for (const menuItem of form.menuItems) {
                if (values[value].serial === menuItem.serial) {
                  menuItemExists = true;
                }
              }
              if (!menuItemExists) form.menuItems.push({ ...values[value] });
            } else form.menuItems.push({ ...values[value] });
            form.defaultItem = { ...values[value] };
            break;
          }
        }
      }
    }
    setFormLayout(nestedCopy(formLayoutCopy));
  };
  return (
    <>
      {instrument !== "" ? (
        <Grid container justifyContent="flex-start">
          <IconButton onClick={handleBackClick}>
            <ArrowBackIcon />
          </IconButton>
        </Grid>
      ) : null}
      {instrument === "" ? (
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            minWidth: 300,
            width: "100%",
            alignContent: "center",
            justifyContent: "center",
          }}
        >
          {images.map((image) => (
            <ImageButton
              focusRipple
              key={image.title}
              style={{
                height: image.height || "100%",
                width: image.width,
              }}
              onClick={() => {
                onInstrumentClick(image.key);
              }}
            >
              <ImageSrc style={{ backgroundImage: `url(${image.url})` }} />
              <ImageBackdrop className="MuiImageBackdrop-root" />
              <Image>
                <Typography
                  component="span"
                  variant="subtitle1"
                  color="inherit"
                  sx={{
                    position: "relative",
                    p: 4,
                    pt: 2,
                    pb: (theme) => `calc(${theme.spacing(1)} + 6px)`,
                  }}
                >
                  {image.title}
                  <ImageMarked className="MuiImageMarked-root" />
                </Typography>
              </Image>
            </ImageButton>
          ))}
        </Box>
      ) : (
        <Table
          headCells={headCells}
          tableItems={tableItems}
          tableCount={tableCount}
          name={processInstrument(instrument)}
          tableButtons={Controls.InstrumentTableButtons}
          initialValues={instrumentForm.initialValues}
          editInitialValues={
            editFormLayout ? StandEditForm.initialValues : null
          }
          formLayout={formLayout}
          editFormLayout={editFormLayout}
          changeFormLayout={changeFormLayout}
          formButtons={Controls.InstrumentSubmitButtons}
          handleAdd={handleAdd}
          handleEdit={handleEdit}
          handleDelete={handleDelete}
          handleSubmit={handleSubmit}
        />
      )}
    </>
  );
}
