/**
 * Page listing all saturation exams. Allows CRUD functions for saturation exams and
 * redirect to saturation exams results.
 */
import React, { useEffect, useRef, useState } from "react";
import ExamAPIRouter from "../Routers/ExamAPIRouter";
import TestPopup from "./TestPopup";
import MissingTestForm from "../Form/MissingTestForm";
import Controls from "../controls/Controls";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import CircularProgress from "@mui/material/CircularProgress";
import Table from "../Table/Table";
import {
  burnInForm,
  burnInHeadCells,
  burnInHeaders,
  formatBurnResults,
} from "./ExamConstants";
import AmplifierStatus from "../Amplifiers/AmplifierStatus";
import { MissingCalibrations } from "../Calibration/MissingCalibrations";
import { burnInCheckboxes, BurnInLines } from "../Chart/ChartConstants";
import { openSocket, sendSocketMessage } from "../controls/WebSocket";
import { connect } from "react-redux";
import Chart from "../Chart/Chart";
import {
  changeFormLayout,
  standButton,
  handleContinue,
  handleStop,
  joinExam,
  nestedCopy,
  setUpSocket,
  updateTimer,
  MAX_PRECISION,
  formatTimestampForFilename,
} from "../controls/Constants";
import { Paper } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { CatalogAPIRouter } from "../Routers/CatalogAPIRouter";
import PowerHeadSelection from "../PowerHeads/PowerHeadSelection";

const BurnInTest = (props) => {
  const { changeMessage, clearMessage, user } = props;
  const location = useLocation();
  let _initialFormValues = useRef({ ...burnInForm.initialValues });
  let _initialFormLayout = useRef(nestedCopy(burnInForm.formLayout));
  let socket = useRef(null);
  let filename = useRef("burn_in")
  let item = useRef(location.state ? location.state.item : null);
  let burnInParams = useRef(null);
  let missingCableResults = useRef(null);
  let missingCouplerResults = useRef(null);
  if (item.current) {
    _initialFormLayout.current[1].defaultItem = { ...item.current.amplifier };
    _initialFormLayout.current[1].disabled = true;
    _initialFormValues.current.amplifier = { ...item.current.amplifier };
  }
  let fullStatusData = useRef([]);
  const [burnIns, setBurnIns] = useState(null);
  const [totalBurnIns, setTotalBurnIns] = useState(0);
  const [burnInData, setBurnInData] = useState([]);
  // const [initialValues, setInitialValues] = useState({
  //   ..._initialFormValues.current,
  // });
  const [formLayout, setFormLayout] = useState(
    nestedCopy(_initialFormLayout.current)
  );
  const [cable, setCable] = useState(null);
  const [coupler, setCoupler] = useState(null);
  const [amplifier, setAmplifier] = useState(null);
  const [inProcess, setInProcess] = useState(
    location.state ? (location.state.active ? true : false) : false
  );
  const [missingCalibrations, setMissingCalibrations] = useState(false);
  const [resetChart, setResetChart] = useState(false);
  const [newExam, setNewExam] = useState(false);
  const [openSelection, setOpenSelection] = useState(false);
  const [unitStatus, setUnitStatus] = useState(null);
  const [statusHistory, setStatusHistory] = useState([]);
  const [viewUnitStatus, setViewUnitStatus] = useState(false);
  const [stopExam, setStopExam] = useState(
    location.state
      ? typeof location.state.active === "boolean"
        ? !location.state.active
        : false
      : false
  );
  const [examMetaData, setExamMetaData] = useState(null);
  const [disconnected, setDisconnect] = useState(false);
  const [currentFrequency, setCurrentFrequency] = useState(null);
  const [closed, setClosed] = useState(null);
  const navigate = useNavigate();

  const redirectExam = () => {
    if (location.state && location.state.item)
      return navigate(location.pathname, {
        state: {
          item: location.state ? location.state.item : null,
          name: location.state.name,
        },
      });
  };

  const clear = (repeat) => {
    if (!repeat) {
      redirectExam();
      setStopExam(false);
      setResetChart(false);
      setBurnInData([]);
      setInProcess(false);
      setUnitStatus(null);
      setDisconnect(true);
      setStatusHistory([]);
      // setExamMetaData(null);
    }
    if (socket.current) {
      socket.current.close();
      socket.current = null;
    }
    clearMessage();
  };

  const fetchBurnIns = async () => {
    let examAPIRouter = new ExamAPIRouter();
    await examAPIRouter.getExams(changeMessage, "burn_ins").then((data) => {
      handleBurnIns(data);
    });
  };

  const handleViewUnitStatus = () => {
    setViewUnitStatus(!viewUnitStatus);
  };

  const handleResetChart = () => {
    setResetChart(!resetChart);
  };

  const handleBurnIns = (response, paging) => {
    if (!response || response.length === 0) {
      setBurnIns([]);
    } else {
      setTotalBurnIns(response.length);
      setBurnIns(response);
    }
  };

  const handleDelete = async (id) => {
    let examAPIRouter = new ExamAPIRouter();
    await examAPIRouter.deleteExam(changeMessage, "burn_ins", id).then(fetchBurnIns);
  };

  const handleChartChange = async (pastExam) => {
    let examAPIRouter = new ExamAPIRouter();
    changeFileName(pastExam)
    await examAPIRouter.getExamResults(changeMessage, "burn_ins", pastExam.id).then((res) => {
      if (res && res.results) {
        let formattedResults = formatBurnResults(res.results);
        setBurnInData(formattedResults);
        handleResetChart();
        setUnitStatus(
          res.unit_status
            ? res.unit_status.length
              ? res.unit_status[res.unit_status.length - 1]
              : null
            : null
        );
        setCurrentFrequency(res.results[0].frequency);
        setStatusHistory(res.unit_status);
        clearMessage();
      } else {
        changeMessage("No Results Found.", "red");
      }
    });
  };

  const setUpTest = (values) => {
    _initialFormValues.current = values;
    burnInParams.current = { ...values };
  };

  const updateData = (newData) => {
    setBurnInData((prevData) => [
      ...prevData,
      {
        index: newData.index,
        inputPower: parseFloat(newData.input_power),
        outputPower: parseFloat(newData.output_power),
        readValue: parseFloat(newData.read_value),
        specifiedOutput: parseFloat(newData.specified_output),
        gain: parseFloat(newData.gain),
        timestamp: newData.timestamp,
      },
    ]);
    handleResetChart();
  };

  const handleSocketMessage = (message) => {
    const dataFromServer = JSON.parse(message.data);
    if (dataFromServer.cable || dataFromServer.coupler) {
      setInProcess(false);
      setCable(dataFromServer.cable ? dataFromServer.cable : null);
      setCoupler(dataFromServer.coupler ? dataFromServer.coupler : null);
      missingCableResults.current = dataFromServer.cable
        ? dataFromServer.cable
        : null;
      missingCouplerResults.current = dataFromServer.coupler
        ? dataFromServer.coupler
        : null;
      setMissingCalibrations(true);
    }
    if (dataFromServer.new_frequency || !isNaN(dataFromServer.new_frequency)) {
      setCurrentFrequency(
        parseFloat(dataFromServer.new_frequency.toFixed(MAX_PRECISION))
      );
    } else if (dataFromServer.past_data) {
      const pastdata = formatBurnResults(dataFromServer.past_data);
      setBurnInData(pastdata);
      handleResetChart();
      if (dataFromServer.unit_status) {
        setUnitStatus(
          dataFromServer.unit_status[dataFromServer.unit_status.length - 1]
        );
        setStatusHistory(dataFromServer.unit_status);
      }
    } else if (dataFromServer.new_data) {
      updateData(dataFromServer.new_data);
      updateTimer(changeMessage, dataFromServer.etc);
    } else if (dataFromServer.error) {
      setInProcess(false);
      setStopExam(false);
      changeMessage(dataFromServer.error, "#F08080");
    } else if (dataFromServer.unit_status) {
      setUnitStatus(dataFromServer.unit_status);
      updateStatusHistory(dataFromServer.unit_status);
    } else if (dataFromServer.status) {
      handleStatus(dataFromServer.status, dataFromServer.message);
    } else if (dataFromServer.disconnect) {
      handleDisconnect(dataFromServer.disconnect);
    }
  };

  const handleDisconnect = (disconnect) => {
    if (disconnect.status) {
      setDisconnect(true);
    }
    changeMessage(disconnect.message, "#F08080");
  };

  const changeFileName = (exam) => {
    filename.current = `burn-in_${exam.amplifier.serial}_${formatTimestampForFilename(exam.date_started)}`
  }

  const handleStatus = (status, message = null) => {
    switch (status) {
      case "started":
        if (message && message.exam) {
          changeFileName(message.exam)
        }
        setStopExam(false);
        setInProcess(true);
        break;
      case "stopped":
        setStopExam(true);
        setInProcess(false);
        break;
      case "closed":
        setClosed(true);
        break;
      case "done":
        setInProcess(false);
        setFormLayout(nestedCopy(_initialFormLayout.current));
        changeMessage("Burn In Exam Complete", "#A0D6B4");
        (async () => {
          let examAPIRouter = new ExamAPIRouter();
          await examAPIRouter.getExams(changeMessage, "burn_ins").then((data) => {
            handleBurnIns(data);
          });
        })();
        break;
      default:
    }
  };

  const updateStatusHistory = (status) => {
    let currentIndex = fullStatusData.current.length;
    fullStatusData.current.push({
      index: currentIndex,
      status: status.status,
      timestamp: status.timestamp,
    });
    // setStatusHistory(compressData(fullStatusData.current));
    setStatusHistory((prevData) => [
      ...prevData,
      {
        index: prevData.length,
        status: status.status,
        timestamp: status.timestamp,
      },
    ]);
  };

  const handleSubmit = async (values) => {
    if (!burnInData.length) {
      setUpTest(values);
      setStopExam(false);
      setNewExam(false);
      if (!socket.current) {
        socket.current = setUpSocket(
          "burn_in",
          location.state ? location.state.exam_room : null,
          user ? user.first_name.toLowerCase() : location.state.user,
          setInProcess,
          handleSocketMessage
        );
      }
      const opened = await openSocket(socket.current);
      if (opened) {
        sendSocketMessage(socket.current, {
          toggle: "start",
          message: {
            stand: values.stand.stand,
            cable: values.cable.id,
            coupler: values.coupler.id,
            amplifier: values.amplifier.id,
            power_head: values.powerHead.id,
            frequency: parseFloat(values.frequency),
            input_power: parseFloat(values.inputPower),
            duty_cycle: parseFloat(values.dutyCycle),
            input_offset: parseFloat(values.inputOffset),
            output_offset: parseFloat(values.outputOffset),
            days: parseFloat(values.days),
            hours: parseFloat(values.hours),
            minutes: parseFloat(values.minutes),
            seconds: parseFloat(values.seconds),
            query_delay: parseFloat(values.queryDelay),
            meter_delay: parseFloat(values.meterDelay),
            read_delay: values.readDelay,
            query_unit: values.queryUnit,
            manual: values.manualTest,
          },
        });
      } else {
        return;
      }
    }
  };

  const continueExam = () => {
    setOpenSelection(true);
  };

  const selectParameters = (parameters) => {
    handleContinue(socket.current, {
      message: {
        power_head: parameters.serial,
        duty_cycle: parameters.dutyCycle,
        input_offset: parameters.inputOffset,
        output_offset: parameters.outputOffset,
        read_delay: parameters.readDelay,
        meter_delay: parameters.meterDelay,
        toggle_p1db: parameters.toggleP1dB,
      },
    });
  };

  const queryItems = async () => {
    let catalogAPIRouter = new CatalogAPIRouter();
    let formLayoutCopy = nestedCopy(_initialFormLayout.current);
    await catalogAPIRouter.getCatalogItems(changeMessage, "stands").then((data) => {
      formLayoutCopy[0].menuItems = nestedCopy(data);
    });
    await catalogAPIRouter.getCatalogItems(changeMessage, "amplifiers").then((data) => {
      formLayoutCopy[1].menuItems = nestedCopy(data);
    });
    await catalogAPIRouter.getCatalogItems(changeMessage, "cables").then((data) => {
      formLayoutCopy[2].menuItems = nestedCopy(data);
    });
    await catalogAPIRouter.getCatalogItems(changeMessage, "couplers").then((data) => {
      formLayoutCopy[3].menuItems = nestedCopy(data);
    });
    await catalogAPIRouter.getCatalogItems(changeMessage, "power_heads").then((data) => {
      formLayoutCopy[4].menuItems = nestedCopy(data);
    });
    setFormLayout(nestedCopy(formLayoutCopy));
  };

  const handleNewExam = (state) => {
    if (state) queryItems();
    clearMessage();
    setNewExam(true);
  };

  const heading = () => (
    <Grid container justifyContent="center" alignContent="center">
      <Paper elevation={3}>
        {examMetaData ? (
          <>
            <Grid>
              <Grid>
                <Typography variant="subtitle1">
                  Date Done: {new Date(examMetaData.date_done).toLocaleString()}
                </Typography>
              </Grid>
              <Grid>
                <Typography variant="subtitle1">
                  Frequency Range:{" "}
                  {
                    examMetaData.exam_params.frequency_parameter.frequency_range
                      .low_frequency
                  }
                  -
                  {
                    examMetaData.exam_params.frequency_parameter.frequency_range
                      .high_frequency
                  }
                  GHz Frequency Step:{" "}
                  {examMetaData.exam_params.frequency_parameter.frequency_step}
                  GHz
                </Typography>
              </Grid>
              <Typography variant="subtitle1">
                Power Range:{" "}
                {examMetaData.exam_params.power_parameter.power_range.low_power}
                -
                {
                  examMetaData.exam_params.power_parameter.power_range
                    .high_power
                }
                dBm Input Offset:{" "}
                {examMetaData.exam_params.power_parameter.input_padding}
                dBm Output Offset:{" "}
                {examMetaData.exam_params.power_parameter.output_padding}
                dBm
              </Typography>
            </Grid>
          </>
        ) : null}
      </Paper>
    </Grid>
  );

  const amplifierInfo = () => (
    <>
      {amplifier ? (
        <Typography variant="h6">
          Name : {amplifier.name} Model : {amplifier.model} Serial :{" "}
          {amplifier.serial} Specified Power : {amplifier.spec_power}
        </Typography>
      ) : null}
      {unitStatus ? (
        <>
          <Controls.Fab
            text={viewUnitStatus ? "Hide Status" : "View Unit Status"}
            onClick={handleViewUnitStatus}
          />
          {viewUnitStatus ? (
            <AmplifierStatus
              unitStatus={unitStatus}
              statusHistory={statusHistory}
            />
          ) : null}
        </>
      ) : null}
    </>
  );

  useEffect(() => {
    if (location.state && location.state.stand) {
      (async () => {
        socket.current = await joinExam(
          socket.current,
          "burn_in",
          location.state.stand.exam_room,
          location.state.stand.exam_id,
          location.state.stand.user,
          setInProcess,
          handleSocketMessage
        );
      })();
    } else {
      if (location.state && location.state.item) {
        handleChartChange(location.state.item);
      }
    }

    (async () => {
      let examAPIRouter = new ExamAPIRouter();
      await examAPIRouter.getExams(changeMessage, "burn_ins").then((data) => {
        handleBurnIns(data);
      });
    })();
    return () => {
      if (socket.current) {
        socket.current.close();
        socket.current = null;
      }
    };
  }, []);

  return !burnIns ? (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  ) : (
    <>
      {/* {heading()} */}
      <MissingCalibrations
        cable={cable}
        coupler={coupler}
        parameters={burnInParams.current}
        setCable={setCable}
        setCoupler={setCoupler}
        test={true}
      />
      {standButton(location, socket, disconnected)}
      {amplifierInfo()}
      <PowerHeadSelection
        changeMessage={changeMessage}
        openPopup={openSelection}
        setOpenPopup={setOpenSelection}
        handleSubmit={selectParameters}
        burnIn={true}
      />
      {inProcess || burnInData.length || stopExam ? (
        <>
          <Controls.ChartButtons
            inProcess={inProcess}
            clear={clear}
            data={burnInData}
          />
          <Controls.ExamControls
            inProcess={inProcess}
            handleStop={() => handleStop(socket.current)}
            handleContinue={continueExam}
            stopped={stopExam}
          />
          <Chart
            filename={filename.current}
            chartLabel={"Burn In Test"}
            lines={{ ...BurnInLines }}
            headers={burnInHeaders}
            chartData={burnInData}
            inProcess={inProcess}
            resetChart={resetChart}
            handleResetChart={handleResetChart}
            checkBoxes={nestedCopy(burnInCheckboxes)}
            showCheckBoxes={true}
          />
        </>
      ) : (
        <Table
          headCells={burnInHeadCells}
          tableItems={burnIns}
          initialValues={{
            ..._initialFormValues.current,
          }}
          formLayout={formLayout}
          setFormLayout={setFormLayout}
          changeFormLayout={changeFormLayout}
          name={"Burn In"}
          searchByValue={"amplifier_serial"}
          openPopup={newExam}
          formProcessing={handleNewExam}
          handleSubmit={handleSubmit}
          tableCount={totalBurnIns}
          handleDelete={handleDelete}
          handleChartChange={handleChartChange}
          tableButtons={Controls.TableExamResultsButtons}
          formButtons={Controls.ExamSubmitButtons}
        />
      )}
      <TestPopup
        title={"Uncalibrated Instruments"}
        openPopup={missingCalibrations}
        setOpenPopup={setMissingCalibrations}
      >
        <MissingTestForm
          setOpenPopup={setMissingCalibrations}
          missingCableResults={missingCableResults.current}
          missingCouplerResults={missingCouplerResults.current}
          cable={burnInParams.current ? burnInParams.current.cable.id : null}
          coupler={
            burnInParams.current ? burnInParams.current.coupler.id : null
          }
        />
      </TestPopup>
    </>
  );
};

const mapStateToProps = (state) => {
  return { user: state.user };
};

export default connect(mapStateToProps)(BurnInTest);
