/**
 * Class for amplifier tuning api calls
 */
import { backendLookup, handleResponse } from "../lookup";

export class ExamAPIRouter {
  getAmplifierExams = async (changeMessage, id, page) => {
    try {
      const response = await backendLookup(
        "POST",
        `sat_exams/get_amplifier_exams/`,
        { amplifier: id, page: page }
      );
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  getExamById = async (changeMessage, type, id) => {
    try {
      const response = await backendLookup("GET", `${type}/${id}/`);
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  getExams = async (changeMessage, exam, page) => {
    try {
      const response = await backendLookup("GET", `${exam}/?page=${page || 1}`);
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  getExamResults = async (changeMessage, exam, id) => {
    try {
      const response = await backendLookup("GET", `${exam}/${id}/get_results/`);
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  getRecentSatExams = async (changeMessage) => {
    try {
      const response = await backendLookup("GET", `sat_exams/get_recent/`);
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  getRecentBurnInExams = async (changeMessage) => {
    try {
      const response = await backendLookup("GET", `burn_ins/get_recent/`);
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  deleteExam = async (changeMessage, examType, examId) => {
    try {
      const response = await backendLookup("DELETE", `${examType}/${examId}/`);
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };
}

export const getSatExams = async (changeMessage) => {
  try {
    const response = await backendLookup("GET", `sat_exams/complete/`);
    return handleResponse(response, changeMessage);
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getBurnIns = async (changeMessage) => {
  try {
    const response = await backendLookup("GET", `burn_ins/complete/`);
    return handleResponse(response, changeMessage);
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getExamResults = async (changeMessage, exam, id) => {
  try {
    const response = await backendLookup("GET", `${exam}/${id}/get_results/`);
    return handleResponse(response, changeMessage);
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getDatedExams = async (changeMessage, item, id) => {
  try {
    const response = await backendLookup(
      "GET",
      `sat_exams/${item}_${id}/get_dated_exams/`
    );
    return handleResponse(response, changeMessage);
  } catch (err) {
    console.error(err);
    return [];
  }
};



export default ExamAPIRouter;
