import React, { useEffect, useRef, useState } from "react";
import {
  CalibrationLines,
  cableCalibrationCheckboxes,
  couplerCalibrationCheckboxes,
} from "../Chart/ChartConstants";
import { useLocation, useNavigate } from "react-router-dom";
import {
  changeFormLayout,
  standButton,
  handleContinue,
  handleStop,
  joinExam,
  nestedCopy,
  setUpSocket,
  updateTimer,
  formatTimestampForFilename,
} from "../controls/Constants";
import { CalibrationAPIRouter } from "../Routers/CalibrationAPIRouter";
import { openSocket, sendSocketMessage } from "../controls/WebSocket";
import {
  cableCalibrationCsvHeaders,
  couplerCalibrationCsvHeaders,
  formatCalResults,
  heading,
  itemCalibrationHeadCells,
  popups,
  cableCalibrationForm,
  couplerCalibrationForm,
} from "./CalConstants";
import { CircularProgress, Grid, Typography } from "@mui/material";
import { MissingCalibrations } from "./MissingCalibrations";
import Controls from "../controls/Controls";
import Chart from "../Chart/Chart";
import Table from "../Table/Table";
import { CatalogAPIRouter } from "../Routers/CatalogAPIRouter";
import { connect } from "react-redux";

// export default function ItemCalibration(props) {
function ItemCalibration(props) {
  const { changeMessage, clearMessage, initialCalValues, user } = props;
  const location = useLocation();
  let _initialFormLayout = useRef({});
  let _initialFormValues = useRef(null);
  let filename = useRef("calibrations")
  let item = useRef(location.state ? location.state.item ? location.state.item : null : null);
  let name = useRef(location.state ? location.state.name ? location.state.name : "Cable" : "Cable");
  let socket = useRef(null);
  let missingCalibrations = useRef(null);
  let headers =
    name.current === "Coupler"
      ? couplerCalibrationCsvHeaders
      : cableCalibrationCsvHeaders;
  if (name.current === "Cable") {
    _initialFormValues.current = { ...cableCalibrationForm.initialValues };
    _initialFormLayout.current = nestedCopy(cableCalibrationForm.formLayout);
    _initialFormLayout.current[1].defaultItem = { ...item.current };
    _initialFormLayout.current[1].disabled = true;
    _initialFormValues.current.cable = { ...item.current };
  } else {
    _initialFormValues.current = { ...couplerCalibrationForm.initialValues };
    _initialFormLayout.current = nestedCopy(couplerCalibrationForm.formLayout);
    _initialFormLayout.current[2].defaultItem = { ...item.current };
    _initialFormValues.current.coupler = { ...item.current };
  }
  if (initialCalValues) {
    _initialFormValues.current = initialCalValues;
  }
  const [calibrations, setCalibrations] = useState([]);
  const [totalCals, setTotalCals] = useState(0);
  const [calData, setCalData] = useState([]);
  const [calMetaData, setCalMetaData] = useState(null);
  const [cable, setCable] = useState(null);
  // const [initValues, setInitValues] = useState({
  //   ..._initialFormValues.current,
  // });
  const [formLayout, setFormLayout] = useState(
    nestedCopy(_initialFormLayout.current)
  );
  const [checkBoxes, setCheckBoxes] = useState(
    name.current === "Cable"
      ? cableCalibrationCheckboxes
      : couplerCalibrationCheckboxes
  );
  const [inProcess, setInProcess] = useState(location.state ?
    typeof location.state.active === "boolean" ? location.state.active : false : false
  );
  const [stopCal, setStopCal] = useState(location.state ?
    typeof location.state.active === "boolean" ? !location.state.active : false : false
  );
  const [resetChart, setResetChart] = useState(false);
  const [openPopup, setOpenPopup] = useState(false);
  const [newCal, setNewCal] = useState(false);
  const [disconnected, setDisconnect] = useState(false);
  const [closed, setClosed] = useState(false);
  const navigate = useNavigate();

  const redirectExam = () => {
    if (location.state && location.state.item)
      return navigate(location.pathname, {
        state: {
          item: location.state ? location.state.item : null,
          name: location.state.name,
        },
      });
  };
  const clear = (repeat) => {
    if (!repeat) {
      redirectExam();
      setStopCal(false);
      setResetChart(false);
      setInProcess(false);
      setDisconnect(false);
      setCalMetaData(null);
    }
    if (socket.current) {
      socket.current.close();
      socket.current = null;
    }
    setCalData([]);
    clearMessage();
  };

  const handleCalibrations = (response) => {
    if (!response) {
      setCalibrations([]);
    } else {
      setTotalCals(response.length);
      setCalibrations(response);
    }
  };

  const updateData = (newData) => {
    // setCalData(dataClone);
    setCalData((prevData) => [
      ...prevData,
      {
        index: newData.index,
        frequency: parseFloat(newData.frequency),
        attenuation: parseFloat(newData.attenuation),
        readValue: parseFloat(newData.read_value),
        previous: parseFloat(newData.previous),
        difference: parseFloat(newData.difference),
        cableAtten: isNaN(newData.cableAtten) ? null : parseFloat(newData.cableAtten),
      },
    ]);
    handleResetChart();
  };

  const handleDisconnect = (disconnect) => {
    if (disconnect.status) {
      setInProcess(false);
      // setDisconnect(true);
    }
    changeMessage(disconnect.message, "#F08080");
  };

  const handleSocketMessage = (message) => {
    const dataFromServer = JSON.parse(message.data);
    if (dataFromServer) {
      if (dataFromServer.error) {
        changeMessage(dataFromServer.error, "#F08080");
      } else if (dataFromServer.cable) {
        setInProcess(false);
        setCable(dataFromServer.cable);
        missingCalibrations.current = dataFromServer.cable;
        setOpenPopup(true);
      } else if (dataFromServer.past_data) {
        setCalData(dataFromServer.past_data);
        handleResetChart();
      } else if (dataFromServer.new_data) {
        updateData(dataFromServer.new_data);
        updateTimer(changeMessage, dataFromServer.etc);
      } else if (dataFromServer.saved) {
        let calibrationAPIRouter = new CalibrationAPIRouter();
        calibrationAPIRouter
          .getLatestCalibrationsForItem(
            changeMessage,
            name.current.toLowerCase(),
            item.current.id
          )
          .then((response) => {
            handleCalibrations(response);
          });
      } else if (dataFromServer.status) {
        handleStatus(dataFromServer.status, dataFromServer.message);
      } else if (dataFromServer.disconnect) {
        handleDisconnect(dataFromServer.disconnect);
      }
    }
  };

  const changeFileName = (exam) => {
    filename.current = `${name.current === "Cable" && exam
      ? `cable_${exam.cable.serial}`
      : `coupler_${exam.coupler.serial}`}
        ${formatTimestampForFilename(exam.date_started)}`
  }

  const handleStatus = (status, message = null) => {
    switch (status) {
      case "started":
        if (message && message.exam) {
          changeFileName(message.exam)
        }
        setStopCal(false);
        setInProcess(true);
        break;
      case "stopped":
        setStopCal(true);
        setInProcess(false);
        break;
      case "closed":
        setClosed(true);
        break;
      case "done":
        setInProcess(false);
        setFormLayout(nestedCopy(_initialFormLayout.current));
        changeMessage("Calibration Complete", "#A0D6B4");
        (async () => {
          let calibrationAPIRouter = new CalibrationAPIRouter();
          await calibrationAPIRouter
            .getLatestCalibrationsForItem(
              changeMessage,
              name.current.toLowerCase(),
              item.current.id
            )
            .then((response) => {
              handleCalibrations(response);
            });
        })();

        break;
      default:
    }
  };

  const handleSubmit = async (values) => {
    if (!calData.length) {
      setStopCal(false);
      setNewCal(false);
      if (!socket.current)
        socket.current = setUpSocket(
          "calibration",
          location.state.exam_room,
          user ? user.first_name.toLowerCase() : location.state.user,
          setInProcess,
          handleSocketMessage
        );
      const opened = await openSocket(socket.current);
      if (opened) {
        sendSocketMessage(socket.current, {
          toggle: "start",
          message: {
            stand: values.stand.stand,
            cable: values.cable.id,
            coupler: name.current === "Coupler" ? values.coupler.id : null,
            power_head: name.current === "Coupler" ? values.powerHead.id : null,
            low_freq: values.lowFrequency,
            high_freq: values.highFrequency,
            freq_step: values.freqStep,
            freq_list: values.toggleList ? values.frequencyList.replace(/\s/g, '').split(',') : null,
            toggle_list: values.toggleList,
            input_power: values.inputPower,
            read_delay: values.readDelay,
            input_offset: 0,
            output_offset: values.outputOffset,
            manual: values.manual,
          },
        });
      } else {
        return;
      }
    }
  };

  const handleDelete = async (id) => {
    let calibrationAPIRouter = new CalibrationAPIRouter();
    await calibrationAPIRouter.deleteCalibration(changeMessage, id);
    calibrationAPIRouter
      .getLatestCalibrationsForItem(changeMessage, name.current.toLowerCase(), item.current.id)
      .then((mountedData) => {
        handleCalibrations(mountedData);
      });
  };

  const handleChartChange = async (pastCalibration) => {
    let calibrationAPIRouter = new CalibrationAPIRouter();
    changeFileName(pastCalibration)
    await calibrationAPIRouter
      .getCalibrationResults(changeMessage, pastCalibration.id)
      .then((results) => {
        if (results) {
          setCheckBoxes(
            name.current === "Cable"
              ? cableCalibrationCheckboxes
              : couplerCalibrationCheckboxes
          );
          setCalData(nestedCopy(formatCalResults(results)));
          handleResetChart();
          setCalMetaData({ ...pastCalibration });
        } else {
          changeMessage("No results found.", "#F08080");
        }
      });
  };

  const handleResetChart = () => {
    setResetChart(!resetChart);
  };

  const queryItems = async () => {
    let catalogAPIRouter = new CatalogAPIRouter();
    let formLayoutCopy = nestedCopy(_initialFormLayout.current);
    if (name.current === "Coupler") {
      await catalogAPIRouter.getCatalogItems(changeMessage, "cables").then((data) => {
        if (data) formLayoutCopy[1].menuItems = nestedCopy(data);
      });
      await catalogAPIRouter.getCatalogItems(changeMessage, "power_heads").then((data) => {
        if (data) formLayoutCopy[3].menuItems = nestedCopy(data);
      });
    }
    await catalogAPIRouter.getCatalogItems(changeMessage, "stands").then((data) => {
      if (data) {
        formLayoutCopy[0].menuItems = nestedCopy(data);
        setFormLayout(nestedCopy(formLayoutCopy));
      }
    });
  };

  const handleNewCalibration = (state) => {
    if (state) queryItems(_initialFormLayout.current);
    clearMessage();
    setNewCal(state);
  };

  useEffect(() => {
    let calibrationAPIRouter = new CalibrationAPIRouter();
    if (location.state.stand) {
      (async () => {
        socket.current = await joinExam(
          socket.current,
          "calibration",
          location.state.stand.exam_room,
          location.state.stand.exam_id,
          location.state.stand.user,
          setInProcess,
          handleSocketMessage
        );
      })();
    }
    (async () => {
      await calibrationAPIRouter
        .getLatestCalibrationsForItem(
          changeMessage,
          name.current.toLowerCase(),
          item.current.id
        )
        .then((mountedData) => {
          handleCalibrations(mountedData);
        });
    })();
    return () => {
      if (socket.current) {
        socket.current.close();
        socket.current = null;
      }
    };
  }, []);

  return !calibrations ? (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  ) : (
    <>
      {heading(name.current, item.current, calMetaData)}
      {standButton(location, socket, disconnected)}
      <MissingCalibrations
        cable={cable}
        parameters={{}}
        // parameters={calibrationParams}
        setCable={setCable}
        test={true}
      />
      {inProcess || calData.length || stopCal ? (
        <>
          {calData.length || inProcess || stopCal ? null : (
            <Grid container justifyContent="center">
              <Typography variant="h6" color="error">
                No results found for this calibration.
              </Typography>
            </Grid>
          )}
          <Controls.ChartButtons
            inProcess={inProcess}
            clear={clear}
            data={calData}
          />
          <Grid container justifyContent="center">
            <Controls.CalibrationControls
              inProcess={inProcess}
              handleStop={() => handleStop(socket.current)}
              handleContinue={() => handleContinue(socket.current)}
              closed={closed}
              stopped={stopCal}
            />
          </Grid>
          <Chart
            filename={filename.current}
            lines={{ ...CalibrationLines }}
            headers={headers}
            chartData={calData}
            inProcess={inProcess}
            resetChart={resetChart}
            handleResetChart={handleResetChart}
            checkBoxes={checkBoxes}
            showCheckBoxes={true}
          />
        </>
      ) : (
        <>
          <Table
            exam={true}
            item={item}
            headCells={itemCalibrationHeadCells}
            initialValues={{
              ..._initialFormValues.current,
            }}
            formLayout={formLayout}
            setFormLayout={setFormLayout}
            formProcessing={handleNewCalibration}
            changeFormLayout={changeFormLayout}
            handleSubmit={handleSubmit}
            openPopup={newCal}
            tableItems={calibrations}
            name={name.current + " Calibration"}
            searchByValue={"name"}
            tableCount={totalCals}
            handleDelete={handleDelete}
            handleChartChange={handleChartChange}
            tableButtons={Controls.TableCalResultsButtons}
            formButtons={Controls.CalibrationSubmitButtons}
          />
        </>
      )}
      {popups(openPopup, setOpenPopup, missingCalibrations.current, setInProcess)}
    </>
  );
}

const mapStateToProps = (state) => {
  return { user: state.user };
};

export default connect(mapStateToProps)(ItemCalibration);
