/**
 * Main app handles all the routing to different pages.
 */
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./components/Home/Home";
import Catalog from "./components/Catalog/Catalog";
import Tests from "./components/Test/Tests";
import Cables from "./components/Cables/Cables";
import Couplers from "./components/Couplers/Couplers";
import Amplifiers from "./components/Amplifiers/Amplifiers";
import Calibration from "./components/Calibration/Calibration";
import ItemCalibration from "./components/Calibration/ItemCalibration";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import Instruments from "./components/Instruments/Instruments";
import PowerHeads from "./components/PowerHeads/PowerHeads";
import StandEdit from "./components/Instruments/StandEdit";
import { blue } from "@mui/material/colors";
import AmplifierDetails from "./components/Amplifiers/AmplifierDetails";
import { useState } from "react";
import FourOFour from "./components/FourOFour/FourOFour";
import { GoogleOAuthProvider } from "@react-oauth/google";
import { Provider } from "react-redux";
import store from "./store";
import Layout from "./hocs/Layout";
import FullExam from "./components/FullExam/FullExam";
import SettingsIcon from '@mui/icons-material/Settings';
const ColorModeContext = React.createContext({ toggleColorMode: () => { } });

function App() {
  const theme = useTheme();
  const colorMode = React.useContext(ColorModeContext);
  const [message, setMessage] = useState({ message: "", color: "white" });
  const [settings, setSetting] = useState([{ key: "precision", name: "Precision", value: 5, icon: SettingsIcon, type: "slider", min: 0, max: 5, step: 1 }])

  const changeMessage = (message = "", color = "white") => {
    setMessage({ message: message, color: color });
  };
  const clearMessage = () => {
    changeMessage("", "white");
    // setMessage({ message: "", color: "white" });
  };
  const changeSettings = (newSettings) => {
    setSetting(newSettings)
  }

  return (
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_AUTH}>
      <Provider store={store}>
        <Router>
          <Layout
            mode={theme.palette.mode}
            toggleColorMode={colorMode.toggleColorMode}
            message={message}
            changeMessage={changeMessage}
            clearMessage={clearMessage}
            settings={settings}
            changeSettings={changeSettings}
          >
            <Routes>
              <Route exact path="/" element={<Home />} />
              <Route exact path="/*" element={<FourOFour />} />
              <Route exact path="/catalog" element={<Catalog />} />
              <Route exact path="/instruments" element={
                <Instruments
                  changeMessage={changeMessage}
                  clearMessage={clearMessage} />
              } />
              <Route exact path="/catalog/cables" element={
                <Cables
                  changeMessage={changeMessage}
                  clearMessage={clearMessage} />
              } />
              <Route exact path="/catalog/couplers" element={
                <Couplers
                  changeMessage={changeMessage}
                  clearMessage={clearMessage} />
              } />
              <Route
                exact
                path="/catalog/amplifiers"
                element={
                  <Amplifiers
                    changeMessage={changeMessage}
                    clearMessage={clearMessage} />
                }
              />
              <Route
                exact
                path="/catalog/powerheads"
                element={
                  <PowerHeads
                    changeMessage={changeMessage}
                    clearMessage={clearMessage}
                  />}
              />
              <Route exact path="/stands/edit" element={<StandEdit />} />
              <Route
                exact
                path="/calibration"
                element={
                  <Calibration
                    changeMessage={changeMessage}
                    clearMessage={clearMessage}
                    render={(props) => ({ ...props })}
                  />
                }
              />
              <Route
                exact
                path="/calibration/cable/*"
                element={
                  <ItemCalibration
                    changeMessage={changeMessage}
                    clearMessage={clearMessage}
                  />
                }
              />
              <Route
                exact
                path="/calibration/coupler/*"
                element={
                  <ItemCalibration
                    changeMessage={changeMessage}
                    clearMessage={clearMessage}
                  />
                }
              />
              <Route exact path="/amplifier/*"
                element={<AmplifierDetails
                  changeMessage={changeMessage}
                  clearMessage={clearMessage} />}
              />
              <Route
                exact
                path="/test/*"
                element={
                  <Tests
                    changeMessage={changeMessage}
                    clearMessage={clearMessage}
                  />
                }
              ></Route>
              <Route
                exact
                path="/full/*"
                element={
                  <FullExam
                    changeMessage={changeMessage}
                    clearMessage={clearMessage}
                  />
                }
              ></Route>
            </Routes>
          </Layout>
        </Router>
      </Provider>
    </GoogleOAuthProvider>
  );
}

export default function ToggleColorMode() {
  const [mode, setMode] = React.useState("dark");
  const colorMode = React.useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === "light" ? "dark" : "light"));
      },
    }),
    []
  );

  const theme = React.useMemo(
    () =>
      createTheme({
        palette: {
          mode,
          ...(mode === "light"
            ? {
              // palette values for light mode
              primary: {
                light: blue[400],
                main: blue[600],
                dark: blue[800],
                darker: blue[1000],
              },
            }
            : {
              // palette values for dark mode
              primary: {
                light: blue[300],
                main: blue[500],
                dark: blue[700],
                darker: blue[900],
              },
            }),
        },
      }),
    [mode]
  );
  // const theme = React.useMemo(
  //   () =>
  //     createTheme({
  //       palette: {
  //         mode,
  //         ...(mode === "light"
  //           ? {
  //               // palette values for light mode
  //               primary: amber,
  //               divider: amber[200],
  //               text: {
  //                 primary: grey[900],
  //                 secondary: grey[800],
  //               },
  //             }
  //           : {
  //               // palette values for dark mode
  //               primary: {
  //                 main: "#2e3e81",
  //               },
  //               secondary: {
  //                 main: "#2e93da",
  //               },
  //               background: {
  //                 default: "#303030",
  //                 paper: "#424242",
  //               },
  //               text: {
  //                 primary: "rgba(251,251,251,0.87)",
  //               },
  //               error: {
  //                 main: "#da080a",
  //               },
  //               info: {
  //                 main: "#2daf70",
  //               },
  //               success: {
  //                 main: "#20cb26",
  //               },
  //             }),
  //       },
  //     }),
  //   [mode]
  // );

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <App />
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
}
