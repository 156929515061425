/**
 * Form component for power heads.
 */
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { calibrationFactorTextFields } from "../Catalog/CataConstants";
import { nestedCopy } from "../controls/Constants";
import Controls from "../controls/Controls";

export default function ExpandForm(props) {
    const { values, textFields, expandValues, errors, errorMessages } = props;
    // frequency: "", factor: "", rho: "" 
    const [expandFormValues, setExpandFormValues] = useState([]);
    const handleAddCalFactor = () => {
        setExpandFormValues((prevData) =>
            [...prevData, { ...expandValues }]);
    };

    const handleFactorChange = (e, index) => {
        const { name, value } = e.target;
        let factor_copy = nestedCopy(expandFormValues);
        factor_copy[index][name] = value;
        setExpandFormValues(nestedCopy(factor_copy));
    };

    return (
        <Grid container justifyContent="center">
            {expandFormValues.map((factor, index) => {
                return (
                    <Grid item key={`expandingfield${index}`}>
                        <Controls.TextFields
                            fields={textFields}
                            values={factor}
                            fieldsName="calibrationFactorFields"
                            handleInputChange={(e) => {
                                handleFactorChange(e, index);
                            }}
                            errors={errors}
                            errorMessages={errorMessages}
                        />
                    </Grid>
                );
            })}
            <Grid container justifyContent="center">
                <Controls.Button text="Add Cal Factor" onClick={handleAddCalFactor} />
            </Grid>
        </Grid>
    );
}
