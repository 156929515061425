/**
 * Page listing all power heads. Allows CRUD functions for power heads.
 */
import React, { useEffect, useState } from "react";
import { newPowerHeadForm, powerHeadCells } from "../Catalog/CataConstants";
import { CatalogAPIRouter } from "../Routers/CatalogAPIRouter";
import Table from "../Table/Table";
import CircularProgress from "@mui/material/CircularProgress";
import Grid from "@mui/material/Grid";
import Controls from "../controls/Controls";
import { changeFormLayout, nestedCopy } from "../controls/Constants";

export default function PowerHead(props) {
  const { changeMessage, clearMessage } = props;
  const [powerHeads, setPowerHeads] = useState(null);
  const [totalPowerHeads, setTotalPowerHeads] = useState(0);
  const [formLayout, setFormLayout] = useState(
    nestedCopy(newPowerHeadForm.formLayout)
  );
  const handlePowerHeads = (response) => {
    if (!response) {
      setPowerHeads([]);
      setTotalPowerHeads(0);
    } else {
      setPowerHeads(response);
      setTotalPowerHeads(response.length);
    }
  };
  const queryPowerHeads = async () => {
    let catalogApiRouter = new CatalogAPIRouter();
    await catalogApiRouter.getCatalogItems(changeMessage, "power_heads").then((response) => {
      handlePowerHeads(response);
    });
  };
  const handleEdit = async (values) => {
    let catalogApiRouter = new CatalogAPIRouter();
    const newPowerHead = {
      serial: values.serial,
      name: values.name,
      pulsing: values.pulsing,
      frequency_range: {
        low_frequency: parseFloat(values.lowFrequency),
        high_frequency: parseFloat(values.highFrequency),
      },
      power_range: {
        low_power: parseFloat(values.lowPower),
        high_power: parseFloat(values.highPower),
      },
    };
    return await catalogApiRouter
      .updateCatalogItem(changeMessage, "power_heads", values.id, newPowerHead)
      .then(queryPowerHeads);
  };

  const handleSubmit = async (values) => {
    let catalogApiRouter = new CatalogAPIRouter();
    const newPowerHead = {
      name: values.name,
      serial: values.serial,
      pulsing: values.pulsing,
      frequency_range: {
        low_frequency: parseFloat(values.lowFrequency),
        high_frequency: parseFloat(values.highFrequency),
      },
      power_range: {
        low_power: parseFloat(values.lowPower),
        high_power: parseFloat(values.highPower),
      },
    };
    await catalogApiRouter
      .postCatalogItem(changeMessage, "power_heads", newPowerHead)
      .then(queryPowerHeads);
  };

  const handleDelete = async (id) => {
    let catalogApiRouter = new CatalogAPIRouter();
    await catalogApiRouter.deleteCatalogItem(changeMessage, "power_heads", id).then(queryPowerHeads);
  };

  useEffect(() => {
    let catalogApiRouter = new CatalogAPIRouter();
    catalogApiRouter.getCatalogItems(changeMessage, "power_heads").then((results) => {
      handlePowerHeads(results);
    });
    return () => { };
  }, []);

  return !powerHeads ? (
    <Grid container justifyContent="center">
      <CircularProgress />
    </Grid>
  ) : (
    <Table
      headCells={powerHeadCells}
      tableItems={powerHeads}
      tableButtons={Controls.TableCatalogButtons}
      formButtons={Controls.CatalogButtons}
      initialValues={{ ...newPowerHeadForm.initialValues }}
      formLayout={formLayout}
      setFormLayout={setFormLayout}
      changeFormLayout={changeFormLayout}
      name="Power Head"
      searchByValue="serial"
      handleSubmit={handleSubmit}
      handleEdit={handleEdit}
      handleDelete={handleDelete}
      tableCount={totalPowerHeads}
    />
  );
}
