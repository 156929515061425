/**
 * Component handling majority of tables. Renders all
 * buttons and handle redirects.
 */
import React, { useState } from "react";
import useTable from "../Table/useTable";
import Controls from "../controls/Controls";
import { Search } from "@mui/icons-material";
import AddIcon from "@mui/icons-material/Add";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import TableRow from "@mui/material/TableRow";
import InputAdornment from "@mui/material/InputAdornment";
import { styled } from "@mui/material/styles";
import Toolbar from "@mui/material/Toolbar";
import Grid from "@mui/material/Grid";
import Form from "../Form/Form";
import { checkNestedProperties } from "../controls/Constants";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";

export const TableDiv = styled("div")(({ theme }) => ({
  flexShrink: 0,
  marginLeft: theme.spacing(1),
  marginRight: theme.spacing(1),
}));

export default function Table(props) {
  const [filterFn, setFilterFn] = useState({
    fn: (items) => {
      return items;
    },
  });
  const [openPopup, setOpenPopup] = useState(false);
  const {
    tableItems,
    headCells,
    additional,
    tableCount,
    handleNextPage,
    nextPage,
    searchByValue,
    name,
    handleSubmit,
    handleDelete,
    handleEdit,
    initialValues,
    editInitialValues,
    handleChartChange,
    formLayout,
    setFormLayout,
    changeFormLayout,
    editFormLayout,
    formProcessing,
    tableButtons,
    formButtons,
  } = props;
  const TableButtons = tableButtons;
  const FormButtons = formButtons;

  const {
    TblContainer,
    TblHead,
    page,
    setPage,
    // TblPagination,
    recordsAfterPagingAndSorting,
  } = useTable(
    tableItems,
    headCells,
    filterFn,
    tableCount,
    handleNextPage,
    nextPage
  );

  const handleSearch = (e) => {
    let target = e.target;
    if (page !== 0) setPage(0);
    setFilterFn({
      fn: (items) => {
        if (target.value === "") return items;
        else {
          let searchValue = searchByValue
          if (searchValue.includes("_")) {
            searchValue = searchByValue.split("_")
          }
          return tableItems.filter((item) => {
            // console.log("items", item, target.value, item[target.value])
            if (searchValue instanceof Array) {
              if (searchValue && item[searchValue[0]] && item[searchValue[0]][searchValue[1]]) {
                if (!isNaN(item[searchValue[0]][searchValue[1]]))
                  return item[searchValue[0]][searchValue[1]].toString().includes(target.value)
                else
                  return item[searchValue[0]][searchValue[1]].includes(target.value)
              }
            }
            else if (searchValue && item[searchValue]) {
              return item[searchValue].includes(target.value);
            }
            return false;
          })
        }
      },
    });
  };

  const buttons = (
    index,
    record,
    name,
    handleSubmit,
    handleDelete,
    handleEdit,
    initialValues,
    editInitialValues,
    handleChartChange,
    formLayout,
    editFormLayout
  ) => (
    <TableCell key={"action" + index}>
      <TableButtons
        item={record}
        name={name}
        handleSubmit={handleSubmit}
        handleDelete={handleDelete}
        handleEdit={handleEdit}
        initialValues={initialValues}
        editInitialValues={editInitialValues}
        handleChartChange={handleChartChange}
        formLayout={formLayout}
        editFormLayout={editFormLayout}
      />
    </TableCell>
  );

  const toolbar = () => (
    <Toolbar>
      <Controls.Input
        sx={{
          width: "100%",
          marginTop: "20px",
        }}
        label={"Search " + name}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
        onChange={handleSearch}
      />
      {formLayout ? (
        <Grid container alignItems="center" justifyContent="flex-end">
          <Controls.Button
            text={`Add New ${name}`}
            size="medium"
            variant="contained"
            startIcon={<AddIcon />}
            onClick={() => {
              if (formProcessing) formProcessing(true);
              setOpenPopup(true);
            }}
          />
        </Grid>
      ) : null}
    </Toolbar>
  );

  return (
    <TableDiv>
      {toolbar()}
      <TblContainer>
        <TblHead />
        <TableBody>
          {recordsAfterPagingAndSorting().map((record, index) => (
            <TableRow key={"row" + index}>
              {headCells.map((cells, index) => {
                if (cells.id === "actions") {
                  return buttons(
                    index,
                    record,
                    name,
                    handleSubmit,
                    handleDelete,
                    handleEdit,
                    initialValues,
                    editInitialValues,
                    handleChartChange,
                    formLayout,
                    editFormLayout
                  );
                } else {
                  let property = checkNestedProperties(cells.id, record);
                  if (typeof property === "boolean") {
                    if (property) property = <DoneIcon />;
                    property = <CloseIcon />;
                  }
                  return (
                    <TableCell key={("cell", index)}>{property}</TableCell>
                  );
                }
              })}
            </TableRow>
          ))}
        </TableBody>
      </TblContainer>
      <Controls.Popup
        title={`Add New ${name}`}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
      >
        <Form
          initialValues={initialValues}
          formLayout={formLayout}
          setFormLayout={setFormLayout}
          handleSubmit={handleSubmit}
          additional={additional}
          changeFormLayout={changeFormLayout}
          openPopup={openPopup}
          setOpenPopup={setOpenPopup}
          buttons={FormButtons}
        />
      </Controls.Popup>
    </TableDiv>
  );
}
