/**
 *  Buttons for the calibration results table.
 */
import React, { useState } from "react";
import Controls from "./Controls";
import ShowChartIcon from "@mui/icons-material/ShowChart";
import CloseIcon from "@mui/icons-material/Close";
import DeleteCalibrationForm from "../Form/DeleteCalibrationForm";

export default function TableCalResultsButtons(props) {
  const { item, handleChartChange, handleDelete } = props;
  const [openDeletePopup, setOpenDeletePopup] = useState(false);
  const deleteItem = () => {
    setOpenDeletePopup(true);
  };

  const popUps = () => (
    <>
      <Controls.Popup
        title={`Deleting a ${item ? (item.coupler ? "coupler" : "cable") : ""
          } calibration.`}
        openPopup={openDeletePopup}
        setOpenPopup={setOpenDeletePopup}
      >
        <DeleteCalibrationForm
          item={item}
          handleDelete={handleDelete}
          closePopup={() => {
            setOpenDeletePopup(false);
          }}
        />
      </Controls.Popup>
    </>
  );

  return (
    <>
      <Controls.ActionButton
        color="primary"
        onClick={() => {
          handleChartChange(item);
        }}
      >
        <ShowChartIcon fontSize="small" />
      </Controls.ActionButton>
      <Controls.ActionButton
        color="error"
        onClick={() => deleteItem(props.item)}
      >
        <CloseIcon fontSize="small" />
      </Controls.ActionButton>
      {popUps()}
    </>
  );
}
