import React, { useEffect, useRef, useState } from "react";
import Controls from "../controls/Controls";
import { selectPowerHeadForm } from "../Catalog/CataConstants";
import { nestedCopy } from "../controls/Constants";
import Form from "../Form/Form";
import { InstrumentAPIRouter } from "../Routers/InstrumentAPIRouter";
export default function PowerHeadSelection(props) {
  const { initialValues, changeMessage, openPopup, setOpenPopup, handleSubmit, burnIn } = props;
  let _initialFormLayout = useRef(
    nestedCopy(selectPowerHeadForm(burnIn).formLayout)
  );
  let _initialFormValues = useRef({
    ...selectPowerHeadForm(burnIn).initialValues,
    ...initialValues
  });
  _initialFormLayout.current[2].disabled = true;
  _initialFormLayout.current[3].disabled = true;
  _initialFormLayout.current[4].disabled = true;
  // const [initValues, setInitValues] = useState({
  //   ..._initialFormValues.current,
  // });
  const [formLayout, setFormLayout] = useState(
    nestedCopy(_initialFormLayout.current)
  );

  const handlePowerHeads = (response) => {
    let formLayoutCopy = nestedCopy(_initialFormLayout.current);
    formLayoutCopy[0].menuItems = nestedCopy(response);
    setFormLayout(nestedCopy(formLayoutCopy));
    // changeFormLayout(response[0]);
  };

  const queryPowerHeads = async () => {
    let instrumentAPIRouter = new InstrumentAPIRouter();
    await instrumentAPIRouter
      .getAllInstruments(changeMessage, "power_heads")
      .then((response) => {
        handlePowerHeads(response);
      });
  };

  useEffect(() => {
    if (openPopup) {
      queryPowerHeads();
    }
  }, [openPopup]);

  return (
    <Controls.Popup
      title="Select a power head."
      openPopup={openPopup}
      setOpenPopup={setOpenPopup}
    >
      <Form
        initialValues={{
          ..._initialFormValues.current,
        }}
        formLayout={formLayout}
        handleSubmit={handleSubmit}
        openPopup={openPopup}
        setOpenPopup={setOpenPopup}
        buttons={Controls.InstrumentSubmitButtons}
      />
    </Controls.Popup>
  );
}
