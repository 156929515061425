/**
 *  Component that is rendered when a user tries to delete an item
 */
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import Controls from "../controls/Controls";
export default function DeleteItemForm(props) {
  return (
    <Grid container justifyContent="center">
      {!props.item ? null : (
        <>
          <Typography variant="h6">
            Are you sure you want to delete {props.name} {props.item.name}? Its
            serial number is {props.item.serial}.
          </Typography>
          <Grid container justifyContent="center">
            <Controls.Button
              text="Cancel"
              color="error"
              variant="contained"
              onClick={props.closePopup}
            />
            <Controls.Button
              text="Confirm"
              color="primary"
              variant="contained"
              onClick={() => {
                props.handleDelete(props.item.id);
                props.closePopup();
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
