/**
 * Handles validation of values in the form.
 */

import { mergeBoolObject, mergeObject } from "../controls/Constants";

const validateGenerPower = (name,
  values,
  errorMessages,
  errors,
) => {
  let fieldValues = values;
  let tempMessages = { ...errorMessages };
  let tempErrors = { ...errors };
  if (name === "lowInput" || name === "highInput" || name === "inputPower") {
    if (fieldValues.stand && fieldValues.stand !== "") {
      if (!fieldValues.stand.gener || fieldValues.stand.gener === undefined) {
        tempMessages[
          name
        ] = `Stand does not have an attached generator.`;
        tempErrors[name] = true;
      }
      else {
        if (
          parseFloat(fieldValues[name]) <
          parseFloat(fieldValues.stand.gener.power_range.low_power)
        ) {
          tempMessages[
            name
          ] = `${fieldValues[name]}dBm must be greater than generator low power bound. ${fieldValues.stand.gener.power_range.low_power}dBm`;
          tempErrors[name] = true;
        }
        else if (
          parseFloat(fieldValues[name]) >
          parseFloat(fieldValues.stand.gener.power_range.high_power)
        ) {
          tempMessages[
            name
          ] = `${fieldValues[name]}dBm must be less than generator high power bound. ${fieldValues.stand.gener.power_range.high_power}dBm`;
          tempErrors[name] = true;
        } else {
          tempMessages[name] = "";
          tempErrors[name] = false;
        }
      }
    }
  }
  return { "errors": tempErrors, "errorMessages": tempMessages }
}

const validatePowerHeadPower = (name,
  values,
  errorMessages,
  errors,
) => {
  let fieldValues = values;
  let tempMessages = { ...errorMessages };
  let tempErrors = { ...errors };
  if (name === "lowInput" || name === "highInput" || name === "inputPower") {
    if (fieldValues.powerHead && fieldValues.powerHead !== "") {
      if (
        parseFloat(fieldValues[name]) <
        parseFloat(fieldValues.powerHead.power_range.low_power)
      ) {
        tempMessages[
          name
        ] = `${fieldValues[name]}dBm must be greater than generator low power bound. ${fieldValues.powerHead.power_range.low_power}dBm`;
        tempErrors[name] = true;
      }
      else if (
        parseFloat(fieldValues[name]) >
        parseFloat(fieldValues.powerHead.power_range.high_power)
      ) {
        tempMessages[
          name
        ] = `${fieldValues[name]}dBm must be less than generator high power bound. ${fieldValues.powerHead.power_range.high_power}dBm`;
        tempErrors[name] = true;
      } else {
        tempMessages[name] = "";
        tempErrors[name] = false;
      }
    }
  }
  return { "errors": tempErrors, "errorMessages": tempMessages }
}
const validatePower = (
  name,
  values,
  errorMessages,
  errors,
) => {
  let fieldValues = values;
  let tempMessages = { ...errorMessages };
  let tempErrors = { ...errors };
  if (name === "lowInput" || name === "highInput" || name === "inputPower") {
    if (isNaN(parseFloat(fieldValues[name]))) {
      tempMessages[name] = `${fieldValues[name]} must be a number.`;
      tempErrors[name] = true;
    }
    if (name === "lowInput" && parseFloat(fieldValues[name]) > parseFloat(fieldValues.highInput)) {
      tempMessages[name] = `${fieldValues[name]}dBm must be smaller than upper bound (${fieldValues.highInput}dBm).`;
      tempErrors[name] = true;
    }
    if (name === "highInput" && parseFloat(fieldValues[name]) < parseFloat(fieldValues.lowInput)) {
      tempMessages[name] = `${fieldValues[name]}dBm must be larger than input lower bound (${fieldValues.lowInput}dBm).`;
      tempErrors[name] = true;
    }
  }
  let validateGener = {
    ...validateGenerPower(name, values, tempMessages, tempErrors),
  }
  let validatePowerHead = {
    ...validatePowerHeadPower(name, values, tempMessages, tempErrors),
  }
  tempErrors = mergeBoolObject(tempErrors, validateGener.errors)
  tempErrors = mergeBoolObject(tempErrors, validatePowerHead.errors)
  tempMessages = mergeObject(tempMessages, validateGener.errorMessages)
  tempMessages = mergeObject(tempMessages, validatePowerHead.errorMessages)

  return { "errors": tempErrors, "errorMessages": tempMessages }
}
const validateGenerFreq = (
  name,
  values,
  errorMessages,
  errors,
) => {
  let fieldValues = { ...values };
  let tempMessages = { ...errorMessages };
  let tempErrors = { ...errors };
  if (name === "lowFrequency" || name === "highFrequency" || name === "frequency") {
    if (fieldValues.stand && fieldValues.stand !== "") {
      if (!fieldValues.stand.gener || fieldValues.stand.gener === undefined) {
        tempMessages[
          name
        ] = `Stand does not have an attached generator.`;
        tempErrors[name] = true;
      }
      else {
        if (
          parseFloat(fieldValues[name]) <
          parseFloat(fieldValues.stand.gener.frequency_range.low_frequency)
        ) {
          tempMessages[
            name
          ] = `${fieldValues[name]}GHz must be greater than generator low bound (${fieldValues.stand.gener.frequency_range.low_frequency}GHz).`;
          tempErrors[name] = true;
        }
        else if (
          parseFloat(fieldValues[name]) >
          parseFloat(fieldValues.stand.gener.frequency_range.high_frequency)
        ) {
          tempMessages[
            name
          ] = `${fieldValues[name]}GHz must be less than generator high bound (${fieldValues.stand.gener.frequency_range.high_frequency}GHz).`;
          tempErrors[name] = true;
        }
        else {
          tempMessages[name] = "";
          tempErrors[name] = false;
        }
      }
    }
  }
  return { "errors": tempErrors, "errorMessages": tempMessages }
}
const validateCableFreq = (
  name,
  values,
  errorMessages,
  errors,
) => {
  let fieldValues = values;
  let tempMessages = { ...errorMessages };
  let tempErrors = { ...errors };
  if (!tempErrors[name] && fieldValues.cable && fieldValues.cable !== "") {
    if (name === "lowFrequency" || name === "highFrequency" || name === "frequency") {
      if (
        parseFloat(fieldValues[name]) <
        parseFloat(fieldValues.cable.frequency_range.low_frequency)
      ) {
        tempMessages[
          name
        ] = `${fieldValues[name]}GHz must higher than cable lower bound (${fieldValues.cable.frequency_range.low_frequency}GHz).`;
        tempErrors[name] = true;
      } else if (
        parseFloat(fieldValues[name]) >
        parseFloat(fieldValues.cable.frequency_range.high_frequency)
      ) {
        tempMessages[
          name
        ] = `${fieldValues[name]}GHz must lower than cable high bound (${fieldValues.cable.frequency_range.high_frequency}GHz).`;
        tempErrors[name] = true;
      } else {
        tempMessages[name] = "";
        tempErrors[name] = false;
      }
    }
  }
  return { "errors": tempErrors, "errorMessages": tempMessages }
}

const validateCouplerFreq = (
  name,
  values,
  errorMessages,
  errors,
) => {
  let fieldValues = values;
  let tempMessages = { ...errorMessages };
  let tempErrors = { ...errors };

  if (
    !tempErrors[name] &&
    fieldValues.coupler &&
    fieldValues.coupler !== ""
  ) {
    if (name === "lowFrequency" || name === "highFrequency" || name === "frequency") {
      if (
        parseFloat(fieldValues[name]) <
        parseFloat(fieldValues.coupler.frequency_range.low_frequency)
      ) {
        tempMessages[
          name
        ] = `${fieldValues[name]}GHz must higher than coupler lower bound (${fieldValues.coupler.frequency_range.low_frequency}GHz).`;
        tempErrors[name] = true;
      } else if (
        parseFloat(fieldValues[name]) >
        parseFloat(fieldValues.coupler.frequency_range.high_frequency)
      ) {
        tempMessages[
          name
        ] = `${fieldValues[name]}GHz must lower than coupler high bound (${fieldValues.coupler.frequency_range.high_frequency}GHz).`;
        tempErrors[name] = true;
      }
      else {
        tempMessages[name] = "";
        tempErrors[name] = false;
      }
    }
  }
  return { "errors": tempErrors, "errorMessages": tempMessages }
}

const validateAmplifierFreq = (
  name,
  values,
  errorMessages,
  errors
) => {
  let fieldValues = values;
  let tempMessages = { ...errorMessages };
  let tempErrors = { ...errors };
  if (
    !tempErrors[name] &&
    fieldValues.amplifier &&
    fieldValues.amplifier !== ""
  ) {
    if (name === "lowFrequency" || name === "highFrequency" || name === "frequency") {
      if (
        parseFloat(fieldValues[name]) <
        parseFloat(fieldValues.amplifier.frequency_range.low_frequency)
      ) {
        tempMessages[
          name
        ] = `${fieldValues[name]}GHz must higher than amplifier lower bound (${fieldValues.amplifier.frequency_range.low_frequency}GHz).`;
        tempErrors[name] = true;
      } else if (
        parseFloat(fieldValues[name]) >
        parseFloat(fieldValues.amplifier.frequency_range.high_frequency)
      ) {
        tempMessages[
          name
        ] = `${fieldValues[name]}GHz must lower than amplifier high bound (${fieldValues.amplifier.frequency_range.high_frequency}GHz).`;
        tempErrors[name] = true;
      }
      else {
        tempMessages[name] = "";
        tempErrors[name] = false;
      }
    }
  }
  return { "errors": tempErrors, "errorMessages": tempMessages }
}

const validatePowerHeadFreq = (
  name,
  values,
  errorMessages,
  errors
) => {
  let fieldValues = values;
  let tempMessages = { ...errorMessages };
  let tempErrors = { ...errors };
  if (
    !tempErrors[name] &&
    fieldValues.powerHead &&
    fieldValues.powerHead !== ""
  ) {
    if (name === "lowFrequency" || name === "highFrequency" || name === "frequency") {
      if (
        parseFloat(fieldValues[name]) <
        parseFloat(fieldValues.powerHead.frequency_range.low_frequency)
      ) {
        tempMessages[
          name
        ] = `${fieldValues[name]}GHz must higher than power head lower bound (${fieldValues.powerHead.frequency_range.low_frequency}GHz).`;
        tempErrors[name] = true;
      } else if (
        parseFloat(fieldValues[name]) >
        parseFloat(fieldValues.powerHead.frequency_range.high_frequency)
      ) {
        tempMessages[
          name
        ] = `${fieldValues[name]}GHz must lower than power head high bound (${fieldValues.powerHead.frequency_range.high_frequency}GHz).`;
        tempErrors[name] = true;
      }
      else {
        tempMessages[name] = "";
        tempErrors[name] = false;
      }
    }
  }
  return { "errors": tempErrors, "errorMessages": tempMessages }
}

const invalidInput = (oldMessage, message, type = "input") => {
  let newMessage = `${oldMessage}`
  if (oldMessage === "" && message !== "") {
    newMessage = `Invalid ${type}(s): `
  }
  if (message !== "") {
    newMessage += `${message} `
  }
  return newMessage
}
const isValidFreq = (value, values, message) => {
  let fieldValues = { ...values };
  fieldValues["toggleList"] = false
  fieldValues["frequency"] = value
  let newMessage = `${message}`
  if (Number.isNaN(Number(value))) {
    newMessage = invalidInput(newMessage, `${value} must be a valid number.`)
  }
  else if (!Number.isNaN(Number(value)) && Number(value) < 0) {
    newMessage = invalidInput(newMessage, `${value}GHz must be a positive number.`)
  }
  else if (!Number.isNaN(Number(value))) {
    // Check list of frequencies against catalog/instruments
    let cableValidation = {
      ...validateCableFreq("frequency", fieldValues, { "frequency": "" }, { "frequency": false }),
    }
    let couplerValidation = {
      ...validateCouplerFreq("frequency", fieldValues, { "frequency": "" }, { "frequency": false }),
    }
    let amplifierValidation = {
      ...validateAmplifierFreq("frequency", fieldValues, { "frequency": "" }, { "frequency": false }),
    }
    let generValidation = {
      ...validateGenerFreq("frequency", fieldValues, { "frequency": "" }, { "frequency": false }),
    }
    let powerHeadValidation = {
      ...validatePowerHeadFreq("frequency", fieldValues, { "frequency": "" }, { "frequency": false }),
    }
    newMessage = invalidInput(newMessage, mergeObject({ "frequency": "" }, generValidation.errorMessages).frequency)
    newMessage = invalidInput(newMessage, mergeObject({ "frequency": "" }, cableValidation.errorMessages).frequency)
    newMessage = invalidInput(newMessage, mergeObject({ "frequency": "" }, couplerValidation.errorMessages).frequency)
    newMessage = invalidInput(newMessage, mergeObject({ "frequency": "" }, amplifierValidation.errorMessages).frequency)
    newMessage = invalidInput(newMessage, mergeObject({ "frequency": "" }, powerHeadValidation.errorMessages).frequency)
  }
  return newMessage
}

const validateFreqList = (
  name,
  values,
  errorMessages,
  errors
) => {
  let fieldValues = { ...values };
  let freqList = fieldValues[name].replace(/\s/g, '').split(',')
  let newMessage = ""
  for (let i = 0; i < freqList.length; i++) {
    newMessage = isValidFreq(freqList[i], values, newMessage)
  }
  if (newMessage.charAt(newMessage.length - 1) === ' ') {
    newMessage = newMessage.substring(0, newMessage.length - 1)
  }
  return newMessage
}

const validateFrequency = (
  name,
  values,
  errorMessages,
  errors,
) => {
  let fieldValues = values;
  let tempMessages = { ...errorMessages };
  let tempErrors = { ...errors };

  if (name === "frequency" || name === "lowFrequency" || name === "highFrequency" || name === "freqStep") {
    if (fieldValues["toggleList"]) {
      tempMessages[name] = "";
      tempErrors[name] = false;
    }
    else {
      if (isNaN(parseFloat(fieldValues[name]))) {
        tempMessages[name] = `${fieldValues[name]} must be a number.`;
        tempErrors[name] = true;
      }
      else if (parseFloat(fieldValues[name] < 0)) {
        tempMessages[name] = `${fieldValues[name]} must be greater than 0.`;
        tempErrors[name] = true;
      }
      else if (name === "lowFrequency" && parseFloat(fieldValues[name]) > parseFloat(fieldValues.highFrequency)) {
        tempMessages[
          name
        ] = `Low frequency must be a lower than high frequency.`;
        tempErrors[name] = true;
      }
      else if (name === "highFrequency" && parseFloat(fieldValues[name]) < parseFloat(fieldValues.lowFrequency)) {
        tempMessages[
          name
        ] = `High frequency must be a higher than low frequency.`;
        tempErrors[name] = true;
      } else if (name === "freqStep" &&
        parseFloat(fieldValues.lowFrequency) <
        parseFloat(fieldValues.highFrequency) &&
        parseFloat(fieldValues[name]) === 0
      ) {
        tempMessages[name] = `Frequency step must be greater than 0.`;
        tempErrors[name] = true;
      }
    }
  }
  else if (name === "frequencyList") {
    if (!fieldValues["toggleList"]) {
      tempMessages[name] = "";
      tempErrors[name] = false;
    }
    else {
      tempMessages[name] = validateFreqList(name, values, tempMessages, tempErrors)
      if (tempMessages[name] !== "") {
        tempErrors[name] = true
      }
      else {
        tempErrors[name] = false;
      }
    }
  }
  let cableValidation = {
    ...validateCableFreq(name, values, tempMessages, tempErrors),
  }
  let couplerValidation = {
    ...validateCouplerFreq(name, values, tempMessages, tempErrors),
  }
  let amplifierValidation = {
    ...validateAmplifierFreq(name, values, tempMessages, tempErrors),
  }
  let generValidation = {
    ...validateGenerFreq(name, values, tempMessages, tempErrors),
  }
  let powerHeadValidation = {
    ...validatePowerHeadFreq(name, values, tempMessages, tempErrors),
  }
  tempErrors = mergeBoolObject(tempErrors, generValidation.errors)
  tempErrors = mergeBoolObject(tempErrors, cableValidation.errors)
  tempErrors = mergeBoolObject(tempErrors, couplerValidation.errors)
  tempErrors = mergeBoolObject(tempErrors, amplifierValidation.errors)
  tempErrors = mergeBoolObject(tempErrors, powerHeadValidation.errors)
  tempMessages = mergeObject(tempMessages, generValidation.errorMessages)
  tempMessages = mergeObject(tempMessages, cableValidation.errorMessages)
  tempMessages = mergeObject(tempMessages, couplerValidation.errorMessages)
  tempMessages = mergeObject(tempMessages, amplifierValidation.errorMessages)
  tempMessages = mergeObject(tempMessages, powerHeadValidation.errorMessages)
  return { "errors": tempErrors, "errorMessages": tempMessages }
}

export const validateValues = (
  values,
  errorMessages,
  setErrorMessages,
  errors,
  setErrors
) => {
  let fieldValues = values;
  let tempMessages = { ...errorMessages };
  for (let message in tempMessages) {
    tempMessages[message] = "";
  }
  let tempErrors = { ...errors };
  for (let error in tempErrors) {
    tempErrors[error] = false;
  }
  for (const name in fieldValues) {
    if (name === "dutyCycle") {
      if (isNaN(parseFloat(fieldValues[name]))) {
        tempMessages[name] = `Duty cycle must be a number.`;
        tempErrors[name] = true;
      } else if (parseFloat(fieldValues[name]) < 0) {
        tempMessages[name] = `Duty cycle must be positive.`;
        tempErrors[name] = true;
      } else if (parseFloat(fieldValues[name]) > 100) {
        tempMessages[name] = `Duty cycle must be between 0% and 100%.`;
        tempErrors[name] = true;
      }
    }
    else if (
      name === "days" ||
      name === "hours" ||
      name === "minutes" ||
      name === "seconds" ||
      name === "timeSlicing"
    ) {
      if (isNaN(parseInt(fieldValues[name]))) {
        tempMessages[name] = `${name &&
          name[0].toUpperCase() + name.slice(1)} must be a number.`;
        tempErrors[name] = true;
      } else if (parseInt(fieldValues[name]) < 0) {
        tempMessages[name] = `${name &&
          name[0].toUpperCase() + name.slice(1)} must be positive.`;
        tempErrors[name] = true;
      } else {
        tempMessages[name] = "";
        tempErrors[name] = false;
      }
    } else if (name === "timeSlicing") {
      if (isNaN(parseInt(fieldValues[name]))) {
        tempMessages[name] = `Query time must be a number.`;
        tempErrors[name] = true;
      } else if (parseInt(fieldValues[name]) < 0) {
        tempMessages[name] = `Query time must be positive.`;
        tempErrors[name] = true;
      } else if (parseInt(fieldValues[name]) === 0) {
        tempMessages[name] = `Query time must be greater than 0.`;
        tempErrors[name] = true;
      } else {
        tempMessages[name] = "";
        tempErrors[name] = false;
      }
    } else if (name === "ipaddress") {
      tempMessages[name] = "";
      tempErrors[name] = false;
    } else if (name === "port") {
      tempMessages[name] = "";
      tempErrors[name] = false;
    } else {
      tempMessages[name] =
        fieldValues[name] === ""
          ? `${(name.charAt(0).toUpperCase() + name.slice(1)).split(/(?=[A-Z])/).join(" ")} is required`
          : "";
      tempErrors[name] = fieldValues[name] === "" ? true : false;
    }
    if (fieldValues["toggleList"]) {
      if (tempMessages["lowFrequency"] !== undefined) {
        tempMessages["lowFrequency"] = ""
        tempErrors["lowFrequency"] = false
      }
      if (tempMessages["highFrequency"] !== undefined) {
        tempMessages["highFrequency"] = ""
        tempErrors["highFrequency"] = false
      }
      if (tempMessages["frequency"] !== undefined) {
        tempMessages["frequency"] = ""
        tempErrors["frequency"] = false
      }
      if (tempMessages["freqStep"] !== undefined) {
        tempMessages["freqStep"] = ""
        tempErrors["freqStep"] = false
      }
    }
    else {
      if (tempMessages["frequencyList"] !== undefined) {
        tempMessages["frequencyList"] = ""
        tempErrors["frequencyList"] = false
      }
    }
    let validatePow = validatePower(name,
      fieldValues,
      tempMessages,
      tempErrors)
    let validateFreqs = validateFrequency(name,
      fieldValues,
      tempMessages,
      tempErrors)
    tempErrors = mergeBoolObject(tempErrors, validatePow.errors)
    tempErrors = mergeBoolObject(tempErrors, validateFreqs.errors)
    tempMessages = mergeObject(tempMessages, validatePow.errorMessages)
    tempMessages = mergeObject(tempMessages, validateFreqs.errorMessages)
  }
  setErrorMessages({
    ...tempMessages,
  });
  setErrors({
    ...tempErrors,
  });
  if (fieldValues === values)
    return Object.values(tempMessages).every((x) => x === "");
};
