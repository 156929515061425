/**
 * Component that is rendered when a user tries to delete an item
 */
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import Controls from "../controls/Controls";
export default function DeleteCalibrationForm(props) {
  const { item, closePopup, handleDelete } = props;
  return (
    <Grid container justifyContent="center">
      {!item ? null : (
        <>
          <Typography variant="h6">
            {`Calibration id: ${item.id}
            Calibration Parameters:`}
            {item.calibration_parameter.frequency_parameter.frequency_list ?
              <>
                {
                  item.calibration_parameter.frequency_parameter.listed_freqs.map((freq, index) => {
                    return <>{`${freq.frequency}GHz${index !== item.calibration_parameter.frequency_parameter.listed_freqs.length - 1 ? ',' : ''}`}</>
                  })
                }
              </>
              :
              <>
                {`${item.calibration_parameter.frequency_parameter.frequency_range.low_frequency} GHz
                  to ${item.calibration_parameter.frequency_parameter.frequency_range.high_frequency} GHz`}
              </>
            }
          </Typography>
          <Typography variant="h6">
            Are you sure you want to delete this calibration?
          </Typography>
          <Grid container justifyContent="center">
            <Controls.Button
              text="Cancel"
              color="error"
              variant="contained"
              onClick={closePopup}
            />
            <Controls.Button
              text="Confirm"
              color="primary"
              variant="contained"
              onClick={() => {
                handleDelete(item.id);
                closePopup();
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
