/**
 * Class for calibration parameter api calls
 */
import { backendLookup, handleResponse } from "../lookup";
export class CalibrationAPIRouter {
  getSingleCalibration = async (changeMessage, id) => {
    try {
      const response = await backendLookup("GET", `cals/${id}/`);
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  getAllCalibrations = async (changeMessage, page) => {
    try {
      const response = await backendLookup("GET", `cals/?page=${page || 1}`);
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  getRecentCalibrations = async (changeMessage) => {
    try {
      const response = await backendLookup("GET", `cals/get_recent/`);
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  getLatestCalibrationsForItem = async (changeMessage, type, id) => {
    try {
      const response = await backendLookup(
        "GET",
        `cals/${type}_${id}/get_calibrations/`
      );
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  getCalibrationResults = async (changeMessage, calibrationID) => {
    try {
      const response = await backendLookup(
        "GET",
        `cals/${calibrationID}/get_results/`
      );
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };

  deleteCalibration = async (changeMessage, calibrationID) => {
    try {
      const response = await backendLookup("DELETE", `cals/${calibrationID}/`);
      return handleResponse(response, changeMessage);
    } catch (err) {
      console.error(err);
      return [];
    }
  };
}

export const getCableCals = async (changeMessage,) => {
  try {
    const response = await backendLookup("GET", `cals/cables/`);
    return handleResponse(response, changeMessage);
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getCouplerCals = async (changeMessage,) => {
  try {
    const response = await backendLookup("GET", `cals/couplers/`);
    return handleResponse(response, changeMessage);
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getCalibrationResults = async (changeMessage, calibrationID) => {
  try {
    const response = await backendLookup(
      "GET",
      `cals/${calibrationID}/get_results/`
    );
    return handleResponse(response, changeMessage);
  } catch (err) {
    console.error(err);
    return [];
  }
};

export const getDatedCalibrations = async (changeMessage, parameters) => {
  try {
    const response = await backendLookup(
      "POST",
      `cals/get_dated_cals/`,
      parameters
    );
    return handleResponse(response, changeMessage);
  } catch (err) {
    console.error(err);
    return [];
  }
};
