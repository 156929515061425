/**
 * Main Form component handling profile storage, validation and
 * setup of the form components.
 */
import Grid from "@mui/material/Grid";
import Controls from "../controls/Controls";
import React, { useEffect, useState } from "react";
import { useForm } from "./useForm";
import { validateValues } from "./Validation";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  Typography,
} from "@mui/material";
import ArchiveIcon from "@mui/icons-material/Archive";
import UnarchiveIcon from "@mui/icons-material/Unarchive";
import HistoryToggleOffIcon from "@mui/icons-material/HistoryToggleOff";
import LooksOneIcon from "@mui/icons-material/LooksOne";
import LooksTwoIcon from "@mui/icons-material/LooksTwo";
import Looks3Icon from "@mui/icons-material/Looks3";
import ClearAllIcon from "@mui/icons-material/ClearAll";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import TextFieldResize from "../controls/TextFieldResize";
import ExpandForm from "./ExpandForm";

export default function Form(props) {
  const {
    initialValues,
    formLayout,
    setFormLayout,
    children,
    dataLength,
    toggleManual,
    manual,
    disableSubmit,
    handleSubmit,
    openPopup,
    changeFormLayout,
    setOpenPopup,
    instruments,
  } = props;
  const Buttons = props.buttons;
  const [load, setLoadValue] = useState(true);
  const [save, setSaveValue] = useState(false);
  const [recent, setRecent] = useState(false);
  const [profileOne, setProfileOne] = useState(false);
  const [profileTwo, setProfileTwo] = useState(false);
  const [profileThree, setProfileThree] = useState(false);
  const [adds, setAdds] = useState(false);

  const validate = (values) => {
    return validateValues(
      values,
      errorMessages,
      setErrorMessages,
      errors,
      setErrors
    );
  };

  const {
    values,
    setValues,
    errors,
    setErrors,
    errorMessages,
    setErrorMessages,
    handleInputChange,
  } = useForm(initialValues, false, validate);

  const checkLocalStorage = () => {
    if (localStorage.getItem(`profile0`)) {
      setRecent(true);
    }
    if (localStorage.getItem(`profile1`)) {
      setProfileOne(true);
    }
    if (localStorage.getItem(`profile2`)) {
      setProfileTwo(true);
    }
    if (localStorage.getItem(`profile3`)) {
      setProfileThree(true);
    }
  };

  const handleLoadButton = () => {
    setLoadValue(true);
    setSaveValue(false);
  };

  const handleButtonClick = (profileId) => {
    if (load) {
      loadValues(profileId);
    } else {
      storeValues(profileId);
    }
  };

  const handleProfileClear = () => {
    localStorage.removeItem("profile0");
    localStorage.removeItem("profile1");
    localStorage.removeItem("profile2");
    localStorage.removeItem("profile3");
    setRecent(false);
    setProfileOne(false);
    setProfileTwo(false);
    setProfileThree(false);
  };

  const storeValues = (profileId) => {
    let storedValues = JSON.stringify(values);
    localStorage.setItem(`profile${profileId}`, storedValues);
  };

  const checkDisabled = (formName) => {
    for (const form of formLayout) {
      if (form.menuItemName === formName && !form.disabled) {
        return true
      }
    }
    return false
  }

  const loadValues = (profileId) => {
    if (changeFormLayout)
      try {
        let loadedValues = JSON.parse(
          localStorage.getItem(`profile${profileId}`)
        );
        let valuesCopy = { ...values };
        for (const value in loadedValues) {
          if (value in values &&
            ((values[value] === "" || typeof (values[value]) === "boolean") || checkDisabled(value))) {
            valuesCopy[value] = loadedValues[value];
          }
        }
        changeFormLayout(loadedValues, formLayout, setFormLayout);
        setValues({ ...values, ...valuesCopy });
      } catch (error) {
        console.log("load value errors", error);
      }
  };

  const handleSliderChange = (event, newValue, valueName) => {
    let valuesCopy = { ...values };
    valuesCopy[valueName] = parseInt(newValue);
    setValues(valuesCopy);
  };

  const handleOffsetChange = (event, valueName) => {
    let valuesCopy = { ...values };
    valuesCopy[valueName] =
      event.target.value === "" ? "" : Number(event.target.value);
    setValues(valuesCopy);
  };

  const handleBlur = (valueName) => {
    let valuesCopy = { ...values };
    if (values[valueName] < 0) {
      valuesCopy[valueName] = 0;
      setValues(valuesCopy);
    } else if (values[valueName] > 100) {
      valuesCopy[valueName] = 100;
      setValues(valuesCopy);
    }
  };

  const handleAdds = () => {
    setAdds(!adds);
  };
  const handleCheckboxChange = (event, valueName) => {
    let valuesCopy = { ...values };
    valuesCopy[valueName] = event.target.checked;
    setValues({ ...valuesCopy });
  };

  const selectForm = (
    values,
    errors,
    menuItems,
    menuItemName,
    handleInputChange,
    setValues,
    instruments,
    menuItemTextValue,
    label,
    disabled,
    defaultItem,
    changeInputsOnSelect,
    index
  ) => (
    <Grid item key={`${menuItemName}${index}`}>
      <Controls.Select
        values={values}
        errors={errors}
        menuItems={menuItems}
        menuItemName={menuItemName}
        handleInputChange={handleInputChange}
        instruments={instruments}
        setValues={setValues}
        menuItemTextValue={menuItemTextValue}
        label={label}
        disabled={disabled}
        defaultItem={defaultItem}
        changeInputsOnSelect={changeInputsOnSelect}
      />
    </Grid>
  );

  const textFields = (
    fields,
    values,
    fieldName,
    handleInputChange,
    errors,
    errorMessages,
    disabled,
    size,
    index
  ) => (
    <Grid item key={`textfields${index}`}>
      <Controls.TextFields
        fields={fields}
        values={values}
        fieldsName={fieldName}
        handleInputChange={handleInputChange}
        errors={errors}
        errorMessages={errorMessages}
        disabled={disabled}
        size={size}
      />
    </Grid>
  );

  const slider = (
    title,
    name,
    handleSliderChange,
    value,
    handleOffsetChange,
    handleBlur,
    index
  ) => (
    <Grid item key={`slider${index}`}>
      <Controls.Slider
        title={title}
        name={name}
        handleSliderChange={handleSliderChange}
        value={value}
        handleOffsetChange={handleOffsetChange}
        handleBlur={handleBlur}
      />
    </Grid>
  );

  const buttons = (
    dataLength,
    values,
    manual,
    toggleManual,
    disableSubmit,
    handleSubmit,
    index,
    setOpenPopup
  ) => (
    <Grid item key={`buttons${index}`}>
      <Buttons
        dataLength={dataLength}
        values={values}
        validate={validate}
        manual={manual}
        toggleManual={toggleManual}
        disableSubmit={disableSubmit}
        handleSubmit={handleSubmit}
        setOpenPopup={setOpenPopup}
      />
    </Grid>
  );

  const toggle = (values, setValues, name, label, index) => (
    <Grid item key={`toggles${index}`}>
      <FormGroup aria-label="position" row>
        <FormControlLabel
          value={values[name]}
          control={
            <Switch
              color="primary"
              onChange={(event) => {
                setValues({
                  ...values,
                  [name]: event.target.checked,
                });
              }}
              checked={values[name]}
            />
          }
          label={label}
          labelPlacement="top"
        />
      </FormGroup>
    </Grid>
  );

  const profiles = () => (
    <>
      <IconButton
        aria-label="store"
        onClick={() => {
          setSaveValue(true);
          setRecent(false);
          setLoadValue(false);
        }}
        disabled={save}
      >
        <UnarchiveIcon />
      </IconButton>
      <IconButton aria-label="load" onClick={handleLoadButton} disabled={load}>
        <ArchiveIcon />
      </IconButton>
      {recent ? (
        <IconButton
          aria-label="recent"
          onClick={() => {
            handleButtonClick(0);
          }}
          disabled={load && !recent}
        >
          <HistoryToggleOffIcon />
        </IconButton>
      ) : null}
      <IconButton
        aria-label="profile_1"
        onClick={() => {
          handleButtonClick(1);
        }}
        disabled={load && !profileOne}
      >
        <LooksOneIcon />
      </IconButton>
      <IconButton
        text="profile_2"
        onClick={() => {
          handleButtonClick(2);
        }}
        disabled={load && !profileTwo}
      >
        <LooksTwoIcon />
      </IconButton>
      <IconButton
        aria-label="profile_3"
        onClick={() => {
          handleButtonClick(3);
        }}
        disabled={load && !profileThree}
      >
        <Looks3Icon />
      </IconButton>
      <IconButton
        aria-label="clear"
        onClick={() => {
          handleProfileClear();
        }}
      >
        <ClearAllIcon />
      </IconButton>
    </>
  );

  const expandForm = (
    values,
    setValues,
    name,
    fieldsName,
    formTextFields,
    expandValues,
    errors,
    errorMessages,
    index
  ) => {
    return (
      <ExpandForm
        values={values}
        name={name}
        fieldsName={fieldsName}
        setValues={setValues}
        textFields={formTextFields}
        expandValues={expandValues}
        errors={errors}
        errorMessages={errorMessages}
        key={`expandingForm${index}`} />
    )

  }

  const expandElement = (
    values,
    setValues,
    name,
    label,
    trueElement,
    falseElement,
    index
  ) => {
    let elements = values[name] ? trueElement.elements : falseElement.elements;
    return (
      <Grid item key={`expandElement${index}`}>
        {toggle(values, setValues, name, label, index)}
        {forms(elements, values, setValues)}
      </Grid>
    );
  };

  const expandingTextField = (
    fields,
    fieldsName,
    handleInputChange,
    errors,
    errorMessages,
    values,
    index
  ) => (
    <Grid item key={`expandingTextField${index}`}>
      <TextFieldResize
        fields={fields}
        fieldsName={fieldsName}
        handleInputChange={handleInputChange}
        errors={errors}
        errorMessages={errorMessages}
        values={values}
      />
    </Grid>
  );

  const checkbox = (values, onChange, name, label, disabled) => (
    <FormControlLabel
      control={
        <Checkbox
          checked={values[name]}
          onChange={(event) => onChange(event, name)}
          name={name}
          disabled={disabled}
        />
      }
      label={label}
      key={`checkbox${label}`}
    />
  );

  const forms = (fields, values, setValues) => {
    if (fields)
      return (
        <>
          {fields.map((form, index) => {
            if (form)
              if (form.type === "select")
                return selectForm(
                  values,
                  errors,
                  form.menuItems,
                  form.menuItemName,
                  handleInputChange,
                  setValues,
                  instruments,
                  form.menuItemTextValue,
                  form.label,
                  form.disabled,
                  form.defaultItem,
                  form.changeInputsOnSelect,
                  index
                );
              else if (form.type === "textfields")
                return textFields(
                  form.fields,
                  values,
                  form.fieldName,
                  handleInputChange,
                  errors,
                  errorMessages,
                  form.disabled,
                  form.size,
                  index
                );
              else if (form.type === "textfieldResize")
                return expandingTextField(
                  form.fields,
                  form.fieldsName,
                  handleInputChange,
                  errors,
                  errorMessages,
                  values,
                  index
                );
              else if (form.type === "slider")
                return slider(
                  form.title,
                  form.name,
                  handleSliderChange,
                  values[form.name],
                  handleOffsetChange,
                  handleBlur,
                  index
                );
              else if (form.type === "buttons") {
                return buttons(
                  dataLength,
                  values,
                  manual,
                  toggleManual,
                  disableSubmit,
                  handleSubmit,
                  index,
                  setOpenPopup
                );
              } else if (form.type === "checkbox") {
                return checkbox(
                  values,
                  handleCheckboxChange,
                  form.name,
                  form.title,
                  form.disabled
                );
              } else if (form.type === "toggle") {
                return toggle(values, setValues, form.name, form.label, index);
              } else if (form.type === "additional") {
                return (
                  <Grid
                    container
                    justifyContent="center"
                    key={`additional${index}`}
                  >
                    <Controls.Button
                      size="small"
                      variant="outlined"
                      color="secondary"
                      endIcon={
                        adds ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />
                      }
                      text="Additional Parameters"
                      onClick={handleAdds}
                    />
                    {adds ? (
                      <>
                        <Grid container justifyContent="center">
                          <Typography>{form.title}</Typography>
                        </Grid>
                        {forms(form.fields, values, setValues)}
                      </>
                    ) : null}
                  </Grid>
                );
              } else if (form.type === "expand") {
                return expandElement(
                  values,
                  setValues,
                  form.name,
                  form.label,
                  form.trueElement,
                  form.falseElement,
                  index
                );
              } else if (form.type === "expandingForm") {
                return expandForm(
                  values,
                  setValues,
                  form.name,
                  form.fieldsName,
                  form.textfields,
                  form.expandValues,
                  errors,
                  errorMessages,
                  index
                );
              } else return null;
            return null;
          })}
        </>
      );
  };
  // console.log("VALUES", values);
  useEffect(() => {
    checkLocalStorage();
    if (!openPopup) {
      for (const property in values) {
        if (values[property]) {
          let storedValues = JSON.stringify(values);
          localStorage.setItem(`profile0`, storedValues);
          return;
        }
      }
    }
  });

  return (
    <div className="test">
      <Grid container justifyContent="center">
        {profiles()}
      </Grid>
      <Grid container spacing={2} justifyContent="center">
        {forms(formLayout, values, setValues)}
        {children}
      </Grid>
    </div>
  );
}
