/**
 *  Component that is rendered when a user tries to delete an exam
 */
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import React from "react";
import Controls from "../controls/Controls";
export default function DeleteExamForm(props) {
  const { item, closePopup, handleDelete } = props;
  return (
    <Grid container justifyContent="center">
      {!item ? null : (
        <>
          <Typography variant="h6">
            {`Saturation Exam id: ${item.id} Are you sure you want to delete this exam?`}
          </Typography>
          <Grid container justifyContent="center">
            <Controls.Button
              text="Cancel"
              color="error"
              variant="contained"
              onClick={closePopup}
            />
            <Controls.Button
              text="Confirm"
              color="primary"
              variant="contained"
              onClick={() => {
                handleDelete(item.id);
                closePopup();
              }}
            />
          </Grid>
        </>
      )}
    </Grid>
  );
}
