/**
 * Chart component. Handles zooming and
 * chart interactions.
 */
import Grid from "@mui/material/Grid";
import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  Tooltip,
  ReferenceArea,
  ResponsiveContainer,
  Label,
  ReferenceDot,
  Legend,
} from "recharts";
import { Tooltip as MuiTooltip, useTheme } from "@mui/material";
import IconButton from "@mui/material/IconButton";
import ZoomOut from "@mui/icons-material/ZoomOut";
import Controls from "../controls/Controls";
import ChartCheckboxes from "./ChartCheckboxes";
import { ColorTypography } from "../controls/ColoredText";
import { Typography } from "@mui/material";
import ResultsTable from "../Table/ResultsTable";
import {
  nestedCopy,
  convertTime,
  compressData,
  MAX_PRECISION,
} from "../controls/Constants";
import { ResizableBox } from "react-resizable";

let initialState = {
  left: "dataMin",
  right: "dataMax",
  top: "dataMax + 10",
  bottom: "dataMin - 10",
  animation: true,
};
const buttons = {
  left: 0,
  middle: 1,
  right: 2,
};
export default function Chart(props) {
  const {
    checkBoxes,
    showCheckBoxes,
    changeToRangeView,
    filename,
    chartLabel,
    lines,
    columns,
    chartData,
    headers,
    resetChart,
    handleResetChart,
    disableAux,
    limits,
    width,
    height,
    customYAxis,
  } = props;
  const isDarkTheme = useTheme().palette.mode === "dark";
  const [data, setData] = useState([]);
  const [state, setState] = useState({ ...initialState });
  const [zoomIn, setZoomIn] = useState(false);
  const [refAreaLeft, setRefAreaLeft] = useState("");
  const [refAreaRight, setRefAreaRight] = useState("");
  const [rows, setRows] = useState([]);
  const categoryColors = {
    OK: "#B9D3E1",
    WARNING: "#FAD946",
    ERROR: "#920000",
  };

  let initialCBState = {};
  if (checkBoxes)
    for (let i = 0; i < checkBoxes.length; i++) {
      initialCBState[checkBoxes[i].id] = checkBoxes[i].active;
    }
  const [cBState, setCBState] = useState({ ...initialCBState });

  const handleData = (newData) => {
    if (chartLabel && chartLabel.toLowerCase().includes("burn")) {
      let compressedData = compressData(data, newData);
      setData(nestedCopy(compressedData));
    } else {
      setData(nestedCopy(newData));
    }
  };

  const handleCBChange = (event) => {
    setCBState({ ...cBState, [event.target.name]: event.target.checked });
  };

  const changeChartFill = (reverse = false) => {
    if (isDarkTheme || reverse) return "white";
    return "black";
  };

  const getZoomData = (from, to) => {
    let spacing = 3600;
    let zoomData = chartData.slice(from === 0 ? 0 : from, to);
    if (to - from < spacing) {
      return zoomData;
    }
    return compressData(zoomData);
  };

  const getAxisYDomain = (from, to, ref, offset) => {
    var [bottom, top] = [null, null];
    const refData = chartData.slice(from === 0 ? 0 : from - 1, to + 1);
    if (refData[0] && refData[0][ref[0]]) {
      [bottom, top] = [refData[0][ref[0]], refData[0][ref[0]]];
    } else return [null, null];
    if (data.length < chartData.length) {
      let startIndex = 0;
      let endIndex = 0;
      for (let index = 0; index < data.length; index++) {
        if (data[index].index === from) startIndex = index;
        else if (data[index].index === to) {
          endIndex = index;
          break;
        }
      }
      setData((prevData) => [
        ...prevData.slice(0, startIndex),
        ...getZoomData(from, to),
        ...prevData.slice(endIndex, data.length),
      ]);
    }
    for (let index = 0; index < ref.length; index++) {
      for (let dataIndex = 0; dataIndex < refData.length; dataIndex++) {
        if (refData[dataIndex][ref[index]]) {
          let referenceItem = parseFloat(refData[dataIndex][ref[index]]);
          if (referenceItem > parseFloat(top)) {
            top = referenceItem.toFixed(MAX_PRECISION);
          }
          if (referenceItem < parseFloat(bottom)) {
            bottom = referenceItem.toFixed(MAX_PRECISION);
          }
        }
      }
    }
    return [
      parseFloat(
        (parseFloat(bottom) - parseFloat(offset)).toFixed(MAX_PRECISION)
      ),
      parseFloat((parseFloat(top) + parseFloat(offset)).toFixed(MAX_PRECISION)),
    ];
  };

  const calcNewState = (e) => {
    let value = e.deltaY < 0 ? 1 : e.deltaY > 0 ? -1 : "";
    let calcdValue =
      parseFloat(
        parseFloat(e.explicitOriginalTarget.data).toFixed(MAX_PRECISION)
      ) + value;
    return parseFloat(calcdValue.toFixed(MAX_PRECISION));
  };

  const changeXAxis = (e, position) => {
    if (!isNaN(e.deltaY)) {
      if (position === "max") {
        setState({
          left: state.left,
          right: calcNewState(e),
          bottom: state.bottom,
          top: state.top,
        });
      } else if (position === "min") {
        setState({
          left: calcNewState(e),
          right: state.right,
          bottom: state.bottom,
          top: state.top,
        });
      }
    }
  };

  const changeYAxis = (e, position) => {
    if (!isNaN(e.deltaY)) {
      if (position === "max") {
        setState({
          left: state.left,
          right: state.right,
          bottom: state.bottom,
          top: calcNewState(e),
        });
      } else if (position === "min") {
        setState({
          left: state.left,
          right: state.right,
          bottom: calcNewState(e),
          top: state.top,
        });
      }
    }
  };

  const preventScroll = (e, index, visibleTicksCount, changeAxis) => {
    if (index === visibleTicksCount - 1 || index === 0) {
      let position = index === visibleTicksCount - 1 ? "max" : "min";
      changeAxis(e, position);
      setZoomIn(true);
      e.preventDefault();
      e.stopPropagation();
    }
  };

  const customXTick = (props) => {
    const { x, y, payload, index, visibleTicksCount, key } = props;
    return (
      <g
        transform={`translate(${x},${y})`}
        onMouseOver={(e) => {
          if (e && e.target)
            e.target.addEventListener(
              "wheel",
              (f) => {
                preventScroll(f, index, visibleTicksCount, changeXAxis);
              },
              {
                passive: false,
              }
            );
        }}
        onMouseLeave={(e) => {
          if (e && e.target)
            e.target.removeEventListener("wheel", preventScroll);
        }}
      >
        <text
          x={0}
          y={0}
          dx={20}
          dy={30}
          textAnchor="end"
          fill={changeChartFill()}
        >
          {key && key.includes("time")
            ?
            convertTime(payload.value)
            : isNaN(payload.value) ? payload.value : parseInt(payload.value)}
        </text>
      </g>
    );
  };

  const customYTick = (props) => {
    const { x, y, payload, index, visibleTicksCount } = props;
    return (
      <g
        transform={`translate(${x},${y})`}
        onMouseOver={(e) => {
          if (e && e.target)
            e.target.addEventListener(
              "wheel",
              (f) => {
                preventScroll(f, index, visibleTicksCount, changeYAxis);
              },
              {
                passive: false,
              }
            );
        }}
        onMouseLeave={(e) => {
          if (e && e.target)
            e.target.removeEventListener("wheel", preventScroll);
        }}
      >
        <text
          x={0}
          y={0}
          dx={-10}
          dy={10}
          textAnchor="end"
          fill={changeChartFill()}
        >
          {isNaN(payload.value) ? payload.value : parseInt(payload.value)}
        </text>
      </g>
    );
  };

  const changeViewWindow = (leftIndex, leftValue, rightIndex, rightValue) => {
    const [bottom, top] = getAxisYDomain(
      leftIndex,
      rightIndex,
      lines.dataKeys,
      10.0
    );
    setState({
      left: leftValue,
      right: rightValue,
      bottom: bottom - 20,
      top: top + 10,
    });
    setRows(chartData.slice(leftIndex, rightIndex + 1));
    if (refAreaLeft !== "") setRefAreaLeft("");
    if (refAreaRight !== "") setRefAreaRight("");
    if (!zoomIn) setZoomIn(true);
  };

  function zoom() {
    if (refAreaLeft.left === refAreaRight.right || refAreaRight === "") {
      setRefAreaLeft("");
      setRefAreaRight("");
      return;
    }
    if (refAreaLeft.left < refAreaRight.right) {
      changeViewWindow(
        refAreaLeft.index,
        refAreaLeft.left,
        refAreaRight.index,
        refAreaRight.right
      );
    } else {
      changeViewWindow(
        refAreaRight.index,
        refAreaRight.right,
        refAreaLeft.index,
        refAreaLeft.left
      );
    }
  }

  function zoomOut() {
    setState(() => ({ ...initialState }));
    setRows(nestedCopy(data));
    setRefAreaLeft("");
    setRefAreaRight("");
    setZoomIn(false);
  }

  const renderToolTip = (payload) => (
    <>
      {Object.keys(payload).map((load, index) => {
        if (lines.toolTip.enabled[load] && payload[load] !== null) {
          return (
            <ColorTypography key={`tooltip${index}`} color={lines.colors[load]}>
              {`${payload.average ? "Average" : ""} ${lines.labels[load]} ${isNaN(parseFloat(payload[load]))
                ? payload[load]
                : parseFloat(
                  parseFloat(payload[load]).toFixed(MAX_PRECISION),
                  MAX_PRECISION
                )
                } ${lines.units[load] || ""}`}
            </ColorTypography>
          );
        }
        return null;
      })}
    </>
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div>
          <ColorTypography color={lines.toolTip.color}>
            {`${lines.toolTip.label} ${lines.toolTip.label.toLowerCase().includes("time")
              ? // ? convertTime(label, "hour", true)
              convertTime(label)
              : `${label} ${lines.toolTip.unit}`
              }`}
          </ColorTypography>
          {renderToolTip(payload[0].payload)}
          <ColorTypography color={changeChartFill()}>
            Click and drag to zoom in. Right click to zoom out.
          </ColorTypography>
        </div>
      );
    }
    return null;
  };

  const referenceDot = () => {
    if (lines.dots) {
      return (
        <>
          {lines.dots.map((dot, index) => {
            return (
              <ReferenceDot
                key={`dot${index}`}
                x={dot.x}
                y={dot.y}
                fill={dot.color}
                name={dot.name}
                label={
                  <Label
                    position={dot.position}
                    fill={dot.color}
                    value={`${dot.label} ${dot.y}${dot.unit}`}
                  />
                }
              />
            );
          })}
        </>
      );
    }
    return null;
  };

  const xAxes = () => {
    return (
      <>
        <XAxis
          dataKey="index"
          xAxisId="2"
          hide={true}
          type="number"
          orientation="top"
        />
        <XAxis
          dataKey={lines.xAxis.dataKey}
          allowDataOverflow={true}
          domain={[
            lines.xAxis.domain
              ? lines.xAxis.domain[0] !== null
                ? lines.xAxis.domain[0]
                : state.left
              : state.left,
            lines.xAxis.domain
              ? lines.xAxis.domain[1] !== null
                ? lines.xAxis.domain[1]
                : state.right
              : state.right,
          ]}
          type="number"
          tick={(tickProps) => {
            return customXTick({ ...tickProps, key: lines.xAxis.dataKey });
          }}
          tickFormatter={(tickItem) => {
            return parseInt(tickItem);
            // return parseFloat(tickItem).toFixed(MAX_PRECISION);
          }}
        >
          <Label position={"bottom"} fill={changeChartFill()} offset={15}>
            {`${lines.xAxis.label} (${lines.xAxis.unit})`}
          </Label>
        </XAxis>
      </>
    );
  };

  const yAxes = () => {
    return (
      <>
        {customYAxis ? (
          <YAxis
            tickCount={2}
            tick={{ fill: changeChartFill() }}
            type={lines.yAxis.type}
            tickFormatter={formatCustomYAxis}
            domain={[0, 1]}
            allowDataOverflow={true}
          >
            <Label
              angle={270}
              position="left"
              fill={changeChartFill()}
              offset={20}
              style={{ textAnchor: "middle", height: "100%" }}
            >
              {`${lines.yAxis.label}`}
            </Label>
          </YAxis>
        ) : (
          <>
            <YAxis
              type={lines.yAxis.type}
              tick={customYTick}
              tickFormatter={(tickItem) => {
                return parseInt(tickItem);
                // return parseFloat(parseFloat(tickItem).toFixed(MAX_PRECISION));
              }}
              domain={[
                lines.yAxis.domain
                  ? lines.yAxis.domain[0] !== null
                    ? lines.yAxis.domain[0]
                    : state.bottom
                  : state.bottom,
                lines.yAxis.domain
                  ? lines.yAxis.domain[1] !== null
                    ? lines.yAxis.domain[1]
                    : state.top
                  : state.top,
              ]}
              allowDataOverflow={true}
            >
              <Label
                angle={270}
                position="left"
                fill={changeChartFill()}
                offset={20}
                style={{ textAnchor: "middle", height: "100%" }}
              >
                {`${lines.yAxis.label}${lines.yAxis.unit && lines.yAxis.unit !== " "
                  ? ` (${lines.yAxis.unit})`
                  : ""
                  }`}
              </Label>
            </YAxis>
          </>
        )}
      </>
    );
  };

  const formatCustomYAxis = (value) => {
    if (value === 0) return "Off";
    if (value === 1) return "On";
    return parseInt(value);
  };

  const CustomizedDot = (props) => {
    const { cx, cy, r, value, name } = props;
    if (limits && limits[name]) {
      const tolerance = 0.1;
      let toleranceValue =
        (parseFloat(limits[name].high) - parseFloat(limits[name].low)) *
        tolerance;
      let withinHighTolerance = parseFloat(limits[name].high) - toleranceValue;
      let withinLowTolerance = parseFloat(limits[name].low) + toleranceValue;
      if (value > withinHighTolerance) {
        return (
          <circle
            cx={cx}
            cy={cy}
            r={r}
            stroke={categoryColors.ERROR}
            strokeWidth={1}
            fill={categoryColors.ERROR}
          />
        );
      }
      if (value < withinLowTolerance) {
        return (
          <circle
            cx={cx}
            cy={cy}
            r={r}
            stroke={categoryColors.WARNING}
            strokeWidth={1}
            fill={categoryColors.WARNING}
          />
        );
      }
    }
    return false;
  };

  useEffect(() => {
    let isMounted = true;
    // if (data.length < chartData.length || resetChart) {
    if (resetChart) {
      if (isMounted) {
        if (resetChart) handleResetChart();
        handleData(chartData);
        // setData(nestedCopy(chartData));
      }
    }

    return () => {
      isMounted = false;
    };
  }, [
    data.length,
    changeToRangeView,
    chartData,
    handleResetChart,
    resetChart,
    zoomIn,
    lines.xAxis.dataKey,
  ]);

  const handleChange = (e) => {
    if (e.button === buttons.right) {
      e.preventDefault();
      zoomOut();
    }
  };
  return !state ? null : (
    <div
      onContextMenu={(e) => {
        e.preventDefault();
        return false;
      }}
      onMouseDown={handleChange}
    >
      {zoomIn ? (
        <IconButton color="primary" onClick={zoomOut}>
          <ZoomOut fontSize="large" />
        </IconButton>
      ) : null}
      <Controls.ScreenShot filename={filename} headers={headers} data={data}>
        <ResizableBox
          width={width ? width : 640}
          height={height ? height : 480}
          minConstraints={[width ? width : 640, height ? height : 480]}
          maxConstraints={[1440, 960]}
        >
          <span>
            <MuiTooltip
              title={
                "Hover over minium/maximum value on chart X or Y axis and use mouse wheel to increase/decrease value."
              }
              followCursor
              arrow
            >
              <Typography variant="h5">{chartLabel}</Typography>
            </MuiTooltip>
            <ResponsiveContainer>
              <LineChart
                width={width || 640}
                height={height || 480}
                data={data}
                margin={{ top: 40, left: 30, bottom: 60, right: 30 }}
                onMouseDown={(e) => {
                  if (e && e.activePayload)
                    setRefAreaLeft({
                      left: e.activeLabel,
                      index: e.activePayload[0].payload.index,
                    });
                }}
                onMouseMove={(e) => {
                  if (
                    e &&
                    (refAreaLeft.left || refAreaLeft.left === 0) &&
                    e.activePayload
                  ) {
                    setRefAreaRight({
                      right: e.activeLabel,
                      index: e.activePayload[0].payload.index,
                    });
                  }
                }}
                onMouseUp={zoom}
              >
                {disableAux ? null : <Legend verticalAlign="top" height={36} />}
                {/* <CartesianGrid strokeDasharray="3 3" /> */}
                {xAxes()}
                {yAxes()}

                <Tooltip
                  content={<CustomTooltip />}
                  wrapperStyle={{
                    border: `2px solid ${changeChartFill(true)}`,
                    borderRadius: "5px",
                    backgroundColor: "#949494",
                    boxShadow: "0px 0px 5px #e5e7eb",
                    paddingLeft: 10,
                    paddingRight: 10,
                  }}
                // labelStyle={{ color: "grey" }}
                />
                {checkBoxes ? (
                  <>
                    {checkBoxes.map((checkBox, index) => {
                      if (cBState[checkBox.id])
                        return (
                          <Line
                            key={`line${index}`}
                            name={lines.labels[checkBox.id]}
                            dataKey={checkBox.id}
                            type="monotone"
                            stroke={lines.colors[checkBox.id]}
                            strokeDasharray={
                              lines.strokeDasharray
                                ? lines.strokeDasharray[checkBox.id]
                                : null
                            }
                            // dot={false}
                            dot={data.length === 1 ? true : <CustomizedDot name={checkBox.id} />}
                            strokeWidth={3}
                            animationDuration={200}
                          />
                        );
                      return null;
                    })}
                  </>
                ) : null}
                {(refAreaLeft.left || refAreaLeft.left === 0) &&
                  (refAreaRight.right || refAreaRight.right === 0) ? (
                  <ReferenceArea
                    x1={refAreaLeft.left}
                    x2={refAreaRight.right}
                    stroke="red"
                    strokeOpacity={0.3}
                  />
                ) : null}
                {referenceDot()}
              </LineChart>
            </ResponsiveContainer>
            {/* </div> */}
          </span>
        </ResizableBox>
      </Controls.ScreenShot>
      <Grid container justifyContent={"center"}>
        {columns ? <ResultsTable columns={columns} rows={rows} /> : null}
        {checkBoxes && showCheckBoxes ? (
          <ChartCheckboxes
            checkBoxes={checkBoxes}
            state={cBState}
            handleChange={handleCBChange}
          />
        ) : null}
      </Grid>
    </div>
  );
}
